<template>
  <div>
    <div id="modals-container"></div>
    <add-associate
      ref="addAssociate"
      @success="refreshAssociates"
    ></add-associate>
    <edit-associate
      ref="editAssociate"
      @successEdit="refreshEditAssociates"
    ></edit-associate>
    <v-row class="pt-3 pr-3">
      <v-spacer></v-spacer>
      <Search-box
        :emitDestination="searchEmitDestination"
        searchPlaceholder="Pretraži klijente"
      ></Search-box>
    </v-row>
    <v-container>
      <v-row style="margin-top: 0px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(item, i) in currentAssociates"
              :key="i"
              hover
              tabindex="0"
            >
              <v-expansion-panel-header
                height="44"
                :class="[
                  item.status !== 'OK' ? 'status-failed-exp' : 'status-ok-exp',
                ]"
              >
                <v-row>
                  <v-col
                    cols="8"
                    sm="5"
                    md="6"
                    lg="3"
                    :class="[densityPadding]"
                  >
                    <div class="pl-1 caption grey--text">Naziv klijenta</div>
                    <div class="pl-1 text-capitalize">{{ item.name }}</div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="3"
                    md="2"
                    lg="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">Oib</div>
                    <div
                      class="text-center"
                      v-for="(oib, i) in item.oibs"
                      :key="i"
                    >
                      {{ oib }}
                    </div>
                  </v-col>
                  <v-col
                    cols="0"
                    sm="4"
                    md="2"
                    lg="3"
                    v-if="!$vuetify.breakpoint.mdAndDown"
                    style="min-width: 100px; max-width: 100%"
                    :class="[densityPadding, 'flex-grow-1']"
                  >
                    <div class="caption grey--text">Adresa</div>
                    <div>{{ item.address }}</div>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="2"
                    md="2"
                    lg="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">Status</div>
                    <div
                      :class="[
                        'one-liner-text',
                        item.status === 'OK'
                          ? 'success--text'
                          : `${
                              item.status === 'PROCESSING'
                                ? 'info--text'
                                : 'error--text'
                            }`,
                        'text-center',
                      ]"
                      @mouseover="hoverOver(`hover-${item.id}`, item)"
                      @mouseleave="item[`hover-${item.id}`] = false"
                      :id="`hover-${item.id}`"
                      :ref="`hover-${item.id}`"
                    >
                      {{ $options.filters.capitalize(item.status) }}
                    </div>
                    <v-tooltip v-model="item[`hover-${item.id}`]" bottom>
                      <!--Fake activator to avoid an attach property which is not working properly -->
                      <template v-slot:activator="{ on }">
                        <div v-on="on"></div>
                      </template>
                      <div style="max-width: 300px">{{ item.status }}</div>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    cols="2"
                    sm="2"
                    md="2"
                    lg="1"
                    xl="1"
                    :class="[densityPadding]"
                  >
                    <div class="d-flex align-start justify-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-0 pt-0"
                            :x-small="density === 'greater'"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="editAssociate(item)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("$vuetify.company.editAssociate") }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="mt-2">
                  <v-col cols="3"
                    ><p class="font-weight-bold">Adresa</p>
                    <v-row class="text-capitalize" dense>{{
                      item.address
                    }}</v-row>
                    <v-row class="text-capitalize" dense
                      >{{ item.zip_code }} {{ item.city }}</v-row
                    >
                    <v-row class="text-capitalize" dense>{{
                      item.country
                    }}</v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-row dense
                      ><v-col class="font-weight-bold" cols="3">Email:</v-col
                      ><v-col cols="9">{{ item.email }}</v-col></v-row
                    >
                    <v-row dense
                      ><v-col class="font-weight-bold" cols="3">Telefon:</v-col
                      ><v-col cols="9">{{ item.phone }}</v-col></v-row
                    >
                    <v-row dense
                      ><v-col class="font-weight-bold" cols="3">Mobitel:</v-col
                      ><v-col cols="9">{{ item.mobile }}</v-col></v-row
                    >
                  </v-col>
                  <v-col cols="3"
                    ><v-row dense
                      ><v-col class="font-weight-bold" cols="2">Šifra:</v-col
                      ><v-col cols="10">{{ item.code }}</v-col></v-row
                    >
                    <v-row dense
                      ><v-col class="font-weight-bold" cols="2">Web:</v-col
                      ><v-col cols="10">{{ item.web }}</v-col></v-row
                    >
                    <v-row dense
                      ><v-col class="font-weight-bold" cols="2">IBAN:</v-col
                      ><v-col cols="10">{{
                        item.ibans && item.ibans.length > 0 ? item.ibans[0] : ""
                      }}</v-col></v-row
                    ></v-col
                  >
                  <v-col
                    style="
                      text-decoration: underline;
                      cursor: pointer;
                      color: #2597c5;
                    "
                    ><v-row @click="receiptOffer('receipts', item.oibs[0])"
                      >Računi</v-row
                    ><v-row @click="receiptOffer('offers', item.oibs[0])"
                      >Ponude</v-row
                    ></v-col
                  >
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <modal
        height="auto"
        width="80%"
        scrollable
        draggable
        resizable
        style="z-index: 200"
        name="receipts"
        class="myTest"
      >
        <Receipts :receiptFilter="receiptFilter"></Receipts>
      </modal>
    </v-container>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import state from '@/state'
import AddAssociate from '@/modules/company/components/AddAssociate'
import EditAssociate from '@/modules/company/components/EditAssociate'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import Receipts from '@/modules/company/components/receipts/Receipts'
import EventBus from '@/plugins/event-bus'

export default {
  components: { AddAssociate, EditAssociate, Receipts },
  mixins: [flow, applicationSettings],
  inject: ['showLoader', 'hideLoader'],
  modules: { Receipts },
  data: function () {
    return {
      receiptFilter: { oibFilter: undefined, reciptOffer: undefined },
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'OIB', value: 'oib', align: 'center' },
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Address', value: 'address', align: 'center' },
        { text: 'Actions', value: 'id', align: 'center' }
      ],
      associates: [],
      currentAssociates: [],
      lastVisible: {},
      listeners: [],
      searchEmitDestination: 'associateSearch'
    }
  },
  watch: {
    associates: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          let currentAssociates = []
          if (this.filter && this.filter !== '') {
            currentAssociates = nv.filter(associate => associate.name?.includes(this.filter.toLowerCase()) || associate.oib?.includes(this.filter.toLowerCase()))
          } else {
            currentAssociates = nv
          }
          currentAssociates = currentAssociates.filter(associate => associate.status !== '_duplicate')
          this.currentAssociates = clone(currentAssociates.sort((a, b) => a.name.localeCompare(b.name)))
        }
      }
    }
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  mounted () {
    const listener = df.doc(`company_associates/${state.getCurrentCompany().id}`).onSnapshot(doc => {
      if (doc && doc.data()) {
        this.associates = Object.keys(doc.data().associates).map(key => {
          return { ...doc.data().associates[key], [`hover-${doc.data().associates[key].id}`]: false }
        })
        this.hideLoader()
      }
    })

    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      this.searchAll(searchObject.searchString)
    })

    this.listeners.push(listener)
  },
  methods: {
    searchAll (val) {
      if (val && val !== '') {
        this.currentAssociates = this.associates.filter(associate => associate.name?.includes(val.toLowerCase()) || associate.oib?.includes(val.toLowerCase()))
      } else {
        this.currentAssociates = this.associates
      }
    },
    receiptOffer (mode, oib) {
      this.receiptFilter.oibFilter = oib
      this.receiptFilter.reciptOffer = mode
      this.receiptFilter.modal = true
      this.$modal.show('receipts')

      /*
      this.$modal.show(Receipts, {
        oibFilter: oib,
        reciptOffer: mode
      }, { draggable: true, resizable: true, scrollable: true, width: '70%', height: 'auto', styles: { 'z-index': 1 } })

      */
      /*  this.$modal.show(
        Receipts,
        {
          oibFilter: oib,
          reciptOffer: mode
        },
        { draggable: true, resizable: true, scrollable: true, width: '70%', height: 'auto', styles: { 'z-index': 1 } }
      ) */
      /* this.$router.push({
        name: 'company.receipts',
        params: {
          oibFilter: oib,
          reciptOffer: mode,
          redirectedThruIssue: true
        }
      }) */
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    addAssociate () {
      this.$refs.addAssociate.open(this.associates)
    },
    editAssociate (associate) {
      this.$refs.editAssociate.open(associate, this.associates)
    },
    refreshAssociates (associate) {
      this.associates.push(
        { ...associate, ...{ status: 'PROCESSING' } }
      )
      this.filter = associate.name
    },
    refreshEditAssociates (associate) {
      this.filter = associate.name
      this.associates = this.associates.map(_associate => {
        if (_associate.id === associate.id) {
          _associate.name = associate.name
          _associate.status = 'PROCESSING'
        }
        return _associate
      })
    }
  }
}
</script>
<style scoped>
.container >>> .v-expansion-panel-header {
  padding: 0 !important;
}

.myTest >>> .vm--modal {
  top: 100px !important;
}
</style>
