<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addItemForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Artikl</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row>
                <v-col>
                  <v-textarea
                    rows="2"
                    dense
                    outlined
                    label="Naziv"
                    autofocus
                    v-model="item.name"
                    :rules="[rules.req]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    dense
                    outlined
                    label="Barkod"
                    v-model="item.barcode"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    outlined
                    label="Šifra"
                    v-model="item.code"
                    @input="checkCodeAvailability"
                    validate-on-blur
                    :rules="[rules.codeused]"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    dense
                    outlined
                    label="Vrsta artikla"
                    v-model="item.type"
                    item-text="name"
                    item-value="value"
                    :items="itemTypes"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-if="!this.warehouseModule"
                    dense
                    outlined
                    label="Skladištna jedinica"
                    v-model="item.storage_unit"
                    item-text="name"
                    item-value="value"
                    :items="storageUnits"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-if="!this.warehouseModule"
                    dense
                    outlined
                    label="Prodajna jedinica"
                    v-model="item.selling_unit"
                    item-text="name"
                    item-value="value"
                    :items="sellingUnits"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-text-field
                  class="pa-2"
                  v-if="warehouseModule"
                  outlined
                  label="Osnovna jedinica"
                >
                </v-text-field>
                <v-checkbox
                  class="pa-2"
                  outlined
                  v-if="warehouseModule"
                  label="Prodajna jedinica"
                >
                </v-checkbox>
              </v-row>
              <v-row class="pa-0">
                <v-col class="d-flex justify-center">
                  <v-checkbox
                    class="pa-2"
                    outlined
                    v-if="warehouseModule"
                    @change="
                      changeDefaultSale(item.warehouse_unit.default_sale)
                    "
                    label="Zadana prodajna jedinica"
                  >
                  </v-checkbox>
                  <v-checkbox
                    class="pa-2"
                    outlined
                    v-if="warehouseModule"
                    @change="
                      changeDefaultStore(item.warehouse_unit.default_stores)
                    "
                    label="Zadana skladišna jedinica"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-col
                v-if="
                  item.warehouse_unit !== undefined &&
                  item.warehouse_unit.custom_item_units.length > 0
                "
              >
                <v-flex
                  v-for="(unit, idx) in item.warehouse_unit.custom_item_units"
                  :key="idx"
                >
                  <v-row class="pa-10">
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        v-model="unit.custom_item_unit"
                        v-if="
                          item.warehouse_unit !== undefined &&
                          item.warehouse_unit.custom_item_units.length > 0
                        "
                        label="Jedinica mjere"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <quantity-input
                        outlined
                        v-if="
                          item.warehouse_unit !== undefined &&
                          item.warehouse_unit.custom_item_units.length > 0
                        "
                        label="Količina"
                        v-model="unit.custom_item_amount"
                      ></quantity-input>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        outlined
                        v-if="
                          item.warehouse_unit !== undefined &&
                          item.warehouse_unit.custom_item_units.length > 0
                        "
                        :value="item.warehouse_unit.storage_unit"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-checkbox
                        outlined
                        width="20%"
                        v-if="
                          item.warehouse_unit !== undefined &&
                          item.warehouse_unit.custom_item_units.length > 0
                        "
                        v-model="unit.selling_item"
                        label="Prodajna jedinica"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-row class="pa-0">
                      <v-col class="d-flex justify-center">
                        <v-checkbox
                          class="pa-2"
                          outlined
                          v-if="
                            item.warehouse_unit !== undefined &&
                            item.warehouse_unit.custom_item_units.length > 0
                          "
                          v-model="unit.default_sale"
                          label="Zadana prodajna jedinica"
                          @change="
                            changeDefaultSaleCustom(unit.default_sale, idx)
                          "
                        >
                        </v-checkbox>
                        <v-checkbox
                          class="pa-2"
                          outlined
                          v-if="
                            item.warehouse_unit !== undefined &&
                            item.warehouse_unit.custom_item_units.length > 0
                          "
                          v-model="unit.default_store"
                          @change="
                            changeDefaultStoreCustom(unit.default_store, idx)
                          "
                          label="Zadana skladišna jedinica"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-divider></v-divider>
                </v-flex>
              </v-col>
              <v-row class="pa-5">
                <v-btn
                  class="white--text okButton"
                  height="45"
                  v-if="this.warehouseModule"
                  color="grey"
                  dark
                  @keyup.enter="addMoreUnits"
                  @click.stop="addMoreUnits"
                  >Dodaj jedinicu</v-btn
                >
                <v-btn
                  class="white--text okButton"
                  height="45"
                  v-if="this.warehouseModule"
                  color="grey"
                  dark
                  @keyup.enter="removeUnits"
                  @click.stop="removeUnits"
                  >Ukloni jedinicu</v-btn
                >
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-row class="justify-center">
                <v-col cols="3">
                  <v-img
                    :src="item.picture"
                    v-if="item.picture"
                    contain
                    height="123px"
                    width="124px"
                    :lazy-src="require('@/assets/no-item-small.jpg')"
                  >
                  </v-img>
                  <v-img
                    v-else
                    height="123px"
                    width="124px"
                    contain
                    :src="
                      defaultImg
                        ? defaultImg
                        : require('@/assets/no-item-small.jpg')
                    "
                  ></v-img>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="6">
                  <v-file-input
                    label="Odaberi sliku artikla"
                    ref="imageUp"
                    style="overflow: hidden"
                    @change="onFileChange"
                    clearable
                    @click:close="onFileChange"
                  >
                    <!-- <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{on, attrs}">
                            <v-btn
                              icon
                              v-on="on"
                              v-bind="attrs"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Izbriši sliku artikla</span>
                        </v-tooltip>
                      </template> -->
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting || isExistingCode"
            type="submit"
          >
            {{ $t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df, auth, functions } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import state from '@/state'
import { clone } from '@/plugins/utils'
import requestChecker from '@/mixins/requestChecker'
import QuantityInput from '../../../components/QuantityInput.vue'

export default {
  components: { QuantityInput },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [requestChecker],
  data: () => ({
    visible: false,
    submitting: false,
    itemTypes: [],
    storageUnits: [],
    sellingUnits: [],
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    selectedLocations: [],
    locations: [],
    item: {},
    defaultImg: '',
    checking: false,
    isExistingCode: false,
    warehouseModule: false,
    listeners: []
  }),
  mounted () {
    this.getMultiplier()
    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }

    this.rules.codeused = (val) => {
      return (
        !this.isExistingCode ||
        `Artikl sa šifrom ${val} već postoji.`
      )
    }
    this.itemTypes = [
      { name: this.$t('$vuetify.lager.itemTypes.goods'), value: 'GOODS' },
      { name: this.$t('$vuetify.lager.itemTypes.service'), value: 'SERVICE' }
    ]

    this.storageUnits = [
      { name: 'Komad', value: 'komad' },
      { name: 'Kilogram', value: 'kilogram' },
      { name: 'Litra', value: 'litra' },
      { name: 'Sat', value: 'sat' },
      { name: 'm2', value: 'm2' },
      { name: 'm', value: 'm' }
    ]

    this.sellingUnits = [
      { name: 'Komad', value: 'komad' },
      { name: 'Kilogram', value: 'kilogram' },
      { name: 'Litra', value: 'litra' },
      { name: 'Sat', value: 'sat' },
      { name: 'm2', value: 'm2' },
      { name: 'm', value: 'm' }
    ]
  },
  methods: {
    changeDefaultSaleCustom (value, index) {
      if (value) {
        if (this.item.warehouse_unit !== undefined && this.item.warehouse_unit.custom_item_units.length > 0) {
          this.item.warehouse_unit.custom_item_units.forEach((_, key) => {
            if (key !== index) {
              this.item.warehouse_unit.custom_item_units[key].default_sale = false
            }
          })
        }
        this.item.warehouse_unit.default_sale = false
      }
    },
    changeDefaultStoreCustom (value, index) {
      if (value) {
        if (this.item.warehouse_unit !== undefined && this.item.warehouse_unit.custom_item_units.length > 0) {
          this.item.warehouse_unit.custom_item_units.forEach((_, key) => {
            if (key !== index) {
              this.item.warehouse_unit.custom_item_units[key].default_store = false
            }
          })
        }
        this.item.warehouse_unit.defalut_store = false
      }
    },
    changeDefaultSale (value) {
      if (value) {
        if (this.item.warehouse_unit !== undefined && this.item.warehouse_unit.custom_item_units.length > 0) {
          this.item.warehouse_unit.custom_item_units.forEach((_, key) => {
            this.item.warehouse_unit.custom_item_units[key].default_sale = false
          })
        }
      }
    },
    changeDefaultStore (value) {
      if (value) {
        if (this.item.warehouse_unit !== undefined && this.item.warehouse_unit.custom_item_units.length > 0) {
          this.item.warehouse_unit.custom_item_units.forEach((_, key) => {
            this.item.warehouse_unit.custom_item_units[key].default_store = false
          })
        }
      }
    },
    addMoreUnits () {
      if (this.item.warehouse_unit !== undefined && this.item.warehouse_unit.custom_item_units !== undefined) {
        this.item.warehouse_unit.custom_item_units.push({ })
      }
    },
    removeUnits () {
      if (this.item.warehouse_unit !== undefined && this.item.warehouse_unit.custom_item_units !== undefined) {
        this.item.warehouse_unit.custom_item_units.pop({ })
      }
    },
    async checkCodeAvailability () {
      const code = this.item.code
      this.checking = true

      if (this.item.code !== undefined && this.item.code !== '') {
        const isExistingCode = await df
          .collection(`companies/${state.getCurrentCompany().id}/items`)
          .where('code', '==', code)
          .where('status', '==', 'OK')
          .where('id', '!=', this.item.id)
          .get()

        this.isExistingCode = !isExistingCode.empty
      } else {
        this.isExistingCode = false
      }
      this.checking = false
    },
    createImage (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.item.picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    onFileChange (file) {
      if (!file) {
        this.item.picture = this.defaultImg
        return
      }
      if (file.size > 500000) {
        this.showMsgBox({
          text: 'Prevelika datoteka, maksimalna dopuštena veličina datoteke je 500 KB.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.item.picture = ''
        this.$refs.imageUp.reset()
        return
      }
      this.createImage(file)
    },
    open (item) {
      this.visible = true
      this.submitting = false
      this.item = clone(item)

      this.defaultImg = item.picture ? clone(item.picture) : ''

      df.doc(
        `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
      )
        .get()
        .then((doc) => {
          if (
            doc.data() &&
            doc.data().locations &&
            Object.keys(doc.data().locations).length > 0
          ) {
            this.locations = Object.keys(doc.data().locations)
              .map((key) => {
                return doc.data().locations[key]
              })
              .filter((location) => location.status === 'OK')
          }
        })
      this.registerRequestParams(this.item)
    },
    close () {
      this.$refs.addItemForm.reset()
      this.$refs.imageUp.reset()
      this.visible = false
    },
    submit () {
      if (!this.$refs.addItemForm.validate()) return
      //   this.showLoader()
      this.submitting = true

      if (this.item.picture && this.item.picture !== this.defaultImg) {
        if (this.item.picture.split(',').length > 0) {
          this.item.picture = this.item.picture.split(',')[1]
        }
      }
      delete this.item.created

      const payload = {
        action: {
          operation: 'update',
          entity: 'item',
          resource_id: this.item.id,
          params: {
            ...this.item,
            ...{ company_id: state.getCurrentCompany().id }
          }
        }
      }

      // Ako je skladiste upaljeno salje se na drugi entitet sa drugim podacima
      if (this.warehouseModule) {
        payload.action.entity = 'warehouse_module_item'
      }
      this.item.name = this.item.name.replace(/\s*$/, '')

      const cleanParams = this.getCleanParams(this.item)
      Object.keys(cleanParams).forEach((key) => {
        delete payload.action.params[key]
      })

      payload.action.params.status = 'OK'

      var companyitemApi = functions.httpsCallable('companyitemapi')

      companyitemApi({
        action: {
          operation: 'update',
          entity: 'item',
          resource_id: payload.action.resource_id,
          params: payload.action.params
        }
      })
        .then((result) => {
          if (result.data.code === 200) {
            this.$emit('refresh')
            this.submitting = false
            this.visible = false
          } else {
            this.showMsgBox({
              text: result.data.message ?? 'Dogodila se pogreška.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.submitting = false
          }
        })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
