<template>
  <div style="width: 100%" class="fill-height">
    <v-row class="pt-3 pr-3">
      <v-spacer></v-spacer>
      <Search-box
        :emitDestination="searchEmitDestination"
        searchPlaceholder="Pretraži artikle"
      ></Search-box>
    </v-row>
    <v-container>
      <v-row
        v-if="listView"
        style="margin-top: 0px"
        class="d-flex justify-center"
      >
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(item, i) in items" :key="i" hover>
            <v-row
              :class="[
                'ma-0',
                item.status === 'OK'
                  ? 'status-ok-exp'
                  : `${
                      item.status === 'PROCESSING'
                        ? 'status-processing-exp'
                        : 'status-failed-exp'
                    }`,
              ]"
              style="height: 48px !important"
            >
              <div
                v-if="density !== 'greater'"
                style="width: 6% !important"
                :class="[densityPadding]"
              >
                <v-img
                  v-if="item.picture"
                  :src="item.picture"
                  contain
                  :height="density === 'normal' ? '44' : '68'"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/no-item.jpg"
                  :height="density === 'normal' ? '44' : '68'"
                ></v-img>
              </div>
              <v-col
                cols="5"
                sm="5"
                md="3"
                lg="3"
                style="
                  min-width: 100px;
                  max-width: 100%;
                  padding-bottom: 10px !important;
                "
                :class="[densityPadding, 'flex-grow-1']"
              >
                <div class="caption grey--text">Naziv</div>
                <div
                  class="one-liner-text"
                  @mouseover="hoverOver(`hover-${item.id}`, item)"
                  @mouseleave="item[`hover-${item.id}`] = false"
                  :id="`hover-${item.id}`"
                  :ref="`hover-${item.id}`"
                >
                  {{ $options.filters.capitalize(item.name) }}
                </div>
                <v-tooltip v-model="item[`hover-${item.id}`]" bottom>
                  <!--Fake activator to avoid an attach property which is not working properly -->
                  <template v-slot:activator="{ on }">
                    <div v-on="on"></div>
                  </template>
                  <div style="max-width: 300px">
                    {{ $options.filters.capitalize(item.name) }}
                  </div>
                </v-tooltip>
              </v-col>
              <v-col cols="3" sm="3" md="2" :class="[densityPadding]">
                <div class="caption grey--text">Šifra</div>
                <div>{{ item.code }}</div>
              </v-col>
              <v-col cols="4" sm="4" md="3" lg="3" :class="[densityPadding]">
                <div class="caption grey--text">Vrsta</div>
                <div>
                  {{ showItemType(item.type) }}
                </div>
              </v-col>
              <v-col cols="6" sm="4" md="2" lg="2" :class="[densityPadding]">
                <div class="caption grey--text">Status</div>
                <v-tooltip bottom>
                  <template #activator="{ on: on }">
                    <div
                      v-on="on"
                      :class="[
                        item.status === 'OK'
                          ? 'success--text'
                          : `${
                              item.status === 'PROCESSING'
                                ? 'info--text'
                                : 'error--text'
                            }`,
                      ]"
                    >
                      {{ translateStatus(item.status) }}
                    </div>
                  </template>
                  {{ getStatusTooltip(item.status) }}
                </v-tooltip>
              </v-col>
              <v-col cols="2" md="1" lg="1" :class="[densityPadding]">
                <div class="d-flex align-start justify-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        :disabled="item.status !== 'OK'"
                        @click="addItemPrice(item)"
                        @keypress.enter="addItemPrice(item)"
                      >
                        <v-icon>mdi-credit-card-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Dodaj artikl na cjenik</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="openEditForm(item)"
                        @keypress.enter="openEditForm(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi artikl</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="d-flex justify-center align-center fill-height ma-0 pa-0"
      >
        <v-card
          class="align-center ma-5"
          v-for="(item, i) in items"
          :key="i"
          hover
          height="300"
          width="250"
        >
          <v-img
            height="190"
            width="250"
            v-if="item.picture"
            :src="item.picture"
          >
          </v-img>
          <v-img height="190" width="250" v-else src="@/assets/no-item.jpg">
          </v-img>
          <div class="mr-4 ml-4">
            <div>
              <h3 class="selling-point-title-text one-liner-text">
                {{ $options.filters.capitalize(item.name) }}
              </h3>
              <p class="grey--text mb-1 selling-point-text one-liner-text">
                Status: {{ item.status }}
              </p>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip v-if="!warehouseModule" bottom>
              <template
                v-if="!warehouseModule"
                v-slot:activator="{ on, attrs }"
              >
                <v-btn
                  v-if="!warehouseModule"
                  class="mt-0 pt-0"
                  :x-small="density === 'greater'"
                  v-on="on"
                  v-bind="attrs"
                  icon
                  :disabled="item.status !== 'OK'"
                  @click="addItemPrice(item)"
                  @keypress.enter="addItemPrice(item)"
                >
                  <v-icon>mdi-credit-card-plus</v-icon>
                </v-btn>
              </template>
              <span>Dodaj artikl na cjenik</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :disabled="item.status === 'PROCESSING'"
                  icon
                  @click="openEditForm(item)"
                  @keypress.enter="openEditForm(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Uredi artikl</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more" class="text--secondary font-italic"></div>
        <div slot="no-results" class="text--secondary font-italic"></div>
      </infinite-loading>
    </v-container>
    <add-item
      v-if="!warehouseModule"
      ref="addItem"
      @refresh="refresh"
    ></add-item>
    <add-wh-item
      v-if="warehouseModule"
      ref="addWhItem"
      @refresh="refresh"
    ></add-wh-item>
    <edit-item
      v-if="!warehouseModule"
      ref="editItem"
      @refresh="refresh"
    ></edit-item>
    <edit-wh-item
      v-if="warehouseModule"
      ref="editWhItem"
      @refresh="refresh"
    ></edit-wh-item>
    <add-pricelist-item v-show="!warehouseModule" ref="addPricelistItem" />
    <add-wh-pricelist-item v-show="warehouseModule" ref="addWhPricelistItem" />
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import addItem from '@/modules/company/components/AddItem'
import editItem from '@/modules/company/components/EditItem'
import addWhItem from '@/modules/company/components/AddWhItem'
import editWhItem from '@/modules/company/components/EditWhItem'
import InfiniteLoading from 'vue-infinite-loading'
import addPricelistItem from '@/modules/point-of-sale/components/AddPricelistItem'
import AddWhPricelistItem from '@/modules/point-of-sale/components/AddWhPricelistItem'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import EventBus from '@/plugins/event-bus'
import { collection, query, onSnapshot, orderBy, where, limit, startAfter } from 'firebase/firestore'
import triGram from '@/mixins/trigram'

export default {
  inject: ['showLoader', 'confirm', 'confirmClose', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker, triGram],
  components: { addItem, InfiniteLoading, editItem, addPricelistItem, AddWhPricelistItem, addWhItem, editWhItem },
  data: () => ({
    loading: false,
    lastiItem: false,
    options: {},
    pagination: {
      itemsPerPage: 10, // TODO: change to 30 after testing.
      page: 1
    },
    headers: [],
    items: [],
    avatarSize: 120,
    lastVisible: '',
    listeners: [],
    newItem: false,
    warehouseModule: false,
    searchEmitDestination: 'lagerSearch',
    lastDocSnapshot: null,
    searchObject: undefined,
    currentQuery: null
  }),
  mounted () {
    this.items = []
    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }

    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      this.searchObject = searchObject
      this.lastDocSnapshot = null
      this.detachListeners()
      this.items = [] // Očisti postojeće artikle
      this.$nextTick(() => {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      })
    })
  },
  computed: {
    listView () {
      return state.isListView()
    }
  },
  beforeDestroy () {
    this.detachListeners()
    EventBus.$off(this.searchEmitDestination)
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },

    companyId (nv, ov) {
      if (nv !== ov) {
        this.detachListeners()
        this.resetHandler()
      }
    }
    // search () {
    //   this.lastVisible = ''
    //   this.resetHandler()
    // }
  },
  methods: {

    refresh () {
      this.lastDocSnapshot = null
      this.detachListeners()
      this.resetHandler()
    },
    showItemType (itemType) {
      if (itemType) {
        return this.$t(`$vuetify.lager.itemTypes.${itemType.toLowerCase()}`)
      } else {
        return ''
      }
    },
    resetHandler () {
      this.items = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    translateStatus (status) {
      if (!status) return ''
      if (status.toUpperCase() === 'OK' || status.toUpperCase() === 'PROCESSING' || status.toUpperCase() === 'ROTO_OK') {
        return status
      } else {
        return this.$t('$vuetify.errors.errorTitle')
      }
    },

    getStatusTooltip (status) {
      if (!status) return ''
      if (status.toUpperCase() === 'OK' || status.toUpperCase() === 'PROCESSING' || status.toUpperCase() === 'ROTO_OK') {
        return status
      } else if (status.toUpperCase() === 'DUPLICATE') {
        return this.$t('$vuetify.errors.duplicateItem')
      } else {
        return status
      }
    },

    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    openaddItemForm () {
      if (this.warehouseModule) {
        this.$refs.addWhItem.open()
      } else {
        this.$refs.addItem.open()
      }
    },
    openEditForm (item) {
      if (this.warehouseModule) {
        this.$refs.editWhItem.open(item)
      } else {
        this.$refs.editItem.open(item)
      }
    },
    addItemPrice (item) {
      if (this.warehouseModule) {
        this.$refs.addWhPricelistItem.open(item)
      } else {
        this.$refs.addPricelistItem.open(item)
      }
    },

    async infiniteHandler ($state) {
      if (this.isLoading) return
      this.isLoading = true

      try {
        const newItemsCount = await this.getItemsNew()
        if (newItemsCount > 0) {
          $state.loaded()
        } else {
          $state.complete()
        }
      } catch (error) {
        console.error('Error in infiniteHandler:', error)
        $state.error()
      } finally {
        this.isLoading = false
      }
    },

    async getItemsNew () {
      // this.detachListeners() // Odspoji postojeće listenere

      const triGramObject = this.searchObject?.triGramObject
      const searchConstraints = []

      if (triGramObject) {
        Object.keys(triGramObject).forEach(name =>
          searchConstraints.push(where(`meta.${name}`, '==', true))
        )
      } else {
        searchConstraints.push(orderBy('name', 'asc'))
      }

      if (this.lastDocSnapshot) {
        searchConstraints.push(startAfter(this.lastDocSnapshot))
      }

      searchConstraints.push(limit(10))

      const constraints = [
        collection(df, `companies/${state.getCurrentCompany().id}/items`),
        ...searchConstraints
      ]

      this.currentQuery = query(...constraints)

      return new Promise((resolve, reject) => {
        const listener = onSnapshot(this.currentQuery,
          (snapshot) => {
            let newItemsCount = 0

            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                newItemsCount++
              }
              this.modifyItems(change, snapshot.docChanges().length)
            })

            if (snapshot.docs.length > 0) {
              this.lastDocSnapshot = snapshot.docs[snapshot.docs.length - 1]
            }

            resolve(newItemsCount)
          },
          (error) => {
            console.error('Error in items listener:', error)
            reject(error)
          }
        )

        this.listeners.push(listener)
      })
    },

    modifyItems (change, len) {
      const data = { _id: change.doc.id, ...change.doc.data() }

      data.id = data._id

      if (change.type === 'added') {
        const index = this.items.findIndex(item => item._id === data._id)
        if (index === -1) {
          if (len < 10) {
            this.items.unshift(data)
          } else {
            this.items.push(data)
          }
        } else {
          this.$set(this.items, index, data)
        }
      } else if (change.type === 'modified') {
        const index = this.items.findIndex(item => item._id === data._id)
        if (index !== -1) {
          this.$set(this.items, index, data)
        } else {
          this.items.push(data)
        }
      } else if (change.type === 'removed') {
        const index = this.items.findIndex(item => item._id === data._id)
        if (index !== -1) {
          this.items.splice(index, 1)
        }
      }

      if (this.searchObject?.triGramObject) {
        this.items.sort((a, b) => a.name.localeCompare(b.name))
      }

      // Forsiraj re-render
      this.$forceUpdate()
    },

    detachListeners () {
      this.listeners.forEach(listener => listener())
      this.listeners = []
    }

  }

}
</script>
