<template>
  <div>
    <add-supplier ref="addSupplier" @success="refreshSuppliers"></add-supplier>
    <edit-supplier
      ref="editSupplier"
      @successEdit="refreshEditSuppliers"
    ></edit-supplier>
    <supplier-items ref="supplierItems"></supplier-items>
    <supplier-integrations ref="supplierIntegrations"></supplier-integrations>

    <v-container>
      <!-- <v-row class="justify-center ma-0 ml-1">
        <v-col cols="2">
          <v-select
            dense
            class="flex-grow-0 flex-shrink-2 mt-0 mb-3"
            outlined
            label="Prodajno mjesto"
            v-model="location"
            :items="locations"
            item-text="name"
            return-object
            :rules="[rules.req]"
            @change="locationSelected()"
            hide-details
          >
          </v-select>
        </v-col>
      </v-row> -->
      <v-btn @click="showAssignSupplier = !showAssignSupplier"
        >Dodjeli globalnog dobavljača</v-btn
      >
      <v-dialog
        v-model="showAssignSupplier"
        @keydown.esc="showAssignSupplier = false"
        persistent
        max-width="750"
        style="z-index: 6"
      >
        <v-card class="pa-5">
          <v-card-title class="d-flex" style="vertical-align: middle">
            <v-row class="pa-3">
              <h3 class="text--secondary">Globalni dobavljač</h3>
              <v-spacer></v-spacer>
              <v-icon large @click="showAssignSupplier = false"
                >mdi-close</v-icon
              >
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-select
                  dense
                  class="flex-grow-0 flex-shrink-0 mt-0 mb-3"
                  outlined
                  label="Dobavljač"
                  v-model="globalSupplier"
                  :items="globalSuppliers"
                  item-text="name"
                  return-object
                  :rules="[rules.req]"
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col>
                <v-btn @click="assignSupplier">Dodijeli dobavljača</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-row class="justify-center mt-5">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(item, i) in suppliers" :key="i" class="mb-2" hover>
            <v-row
              :class="[
                'ma-0',
                item.status === 'OK'
                  ? 'status-ok'
                  : `${
                      item.status === 'PROCESSING'
                        ? 'status-processing'
                        : 'status-failed'
                    }`,
              ]"
            >
              <v-col cols="8" sm="5" md="6" lg="3" :class="[densityPadding]">
                <div class="caption grey--text">Naziv dobavljača</div>
                <div class="text-h5 text-capitalize">{{ item.name }}</div>
              </v-col>
              <v-col cols="4" sm="3" md="2" lg="2" :class="[densityPadding]">
                <div class="caption grey--text text-center">Oib</div>
                <div class="text-center">
                  {{ item.oib }}
                </div>
              </v-col>
              <v-col
                cols="0"
                sm="4"
                md="2"
                lg="3"
                v-if="!$vuetify.breakpoint.mdAndDown"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1']"
              >
                <div class="caption grey--text">Adresa</div>
                <div>{{ item.address }}</div>
              </v-col>
              <v-col cols="6" sm="2" md="2" lg="2" :class="[densityPadding]">
                <div class="caption grey--text text-center">Status</div>
                <!-- <div :class="[, 'text-center']">{{ item.status }}</div> -->
                <div
                  :class="[
                    'text-subtitle-1',
                    'one-liner-text',
                    item.status === 'OK'
                      ? 'success--text'
                      : `${
                          item.status === 'PROCESSING'
                            ? 'info--text'
                            : 'error--text'
                        }`,
                    'text-center',
                  ]"
                  @mouseover="hoverOver(`hover-${item.id}`, item)"
                  @mouseleave="item[`hover-${item.id}`] = false"
                  :id="`hover-${item.id}`"
                  :ref="`hover-${item.id}`"
                >
                  {{ $options.filters.capitalize(item.status) }}
                </div>
                <v-tooltip v-model="item[`hover-${item.id}`]" bottom>
                  <!--Fake activator to avoid an attach property which is not working properly -->
                  <template v-slot:activator="{ on }">
                    <div v-on="on"></div>
                  </template>
                  <div style="max-width: 300px">{{ item.status }}</div>
                </v-tooltip>
              </v-col>
              <v-col cols="2" :class="[densityPadding]">
                <v-row class="pt-3 align-center justify-center">
                  <div class="caption grey--text text-center">Akcije</div>
                </v-row>
                <v-row class="align-center justify-center">
                  <div class="d-flex align-start justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mt-0 pt-0"
                          :x-small="density === 'greater'"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="editSupplier(item)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("$vuetify.company.editSupplier") }}</span>
                    </v-tooltip>
                  </div>
                  <div class="d-flex align-start justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mt-0 pt-0"
                          :x-small="density === 'greater'"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="openSupplierItems(item)"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("$vuetify.company.supplierItems") }}</span>
                    </v-tooltip>
                  </div>
                  <div class="d-flex align-start justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mt-0 pt-0"
                          :x-small="density === 'greater'"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="openSupplierIntegrations(item)"
                        >
                          <v-icon>mdi-email-check</v-icon>
                        </v-btn>
                      </template>
                      <span>Uredi način komunikacije</span>
                    </v-tooltip>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
// import { clone } from '@/plugins/utils'
import state from '@/state'
import AddSupplier from '@/modules/company/components/suppliers/AddSupplier'
import EditSupplier from '@/modules/company/components/suppliers/EditSupplier'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import rules from '@/plugins/rules'
import { v4 as uuidv4 } from 'uuid'
import SupplierItems from '@/modules/company/components/suppliers/SupplierItems'
import SupplierIntegrations from '@/modules/company/components/suppliers/integration/SupplierIntegrations'

export default {
  components: { AddSupplier, EditSupplier, SupplierItems, SupplierIntegrations },
  mixins: [flow, applicationSettings],
  inject: ['showLoader', 'hideLoader'],
  data: function () {
    return {
      rules: {
        req: rules.req()
      },
      locations: [],
      location: undefined,
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'OIB', value: 'oib', align: 'center' },
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Address', value: 'address', align: 'center' },
        { text: 'Actions', value: 'id', align: 'center' }
      ],
      suppliers: [],
      currentSuppliers: [],
      globalSuppliers: [],
      globalSupplier: undefined,
      lastVisible: {},
      listeners: [],
      showAssignSupplier: false
    }
  },
  watch: {
    // suppliers: {
    //   deep: true,
    //   handler (nv, ov) {
    //     if (nv !== ov) {
    //       let currentSuppliers
    //       if (this.filter && this.filter !== '') {
    //         currentSuppliers = nv.filter(supplier => supplier.name?.includes(this.filter.toLowerCase()) || supplier.oib?.includes(this.filter.toLowerCase()))
    //       } else {
    //         currentSuppliers = nv
    //       }
    //       this.currentSuppliers = clone(currentSuppliers.sort((a, b) => a.name.localeCompare(b.name)))
    //     }
    //   }
    // },
    // filter (nv, ov) {
    //   if (nv !== ov) {
    //     let currentSuppliers
    //     if (nv && nv !== '') {
    //       currentSuppliers = this.suppliers.filter(supplier => supplier.name?.includes(nv.toLowerCase()) || supplier.oib?.includes(nv.toLowerCase()))
    //     } else {
    //       currentSuppliers = this.suppliers
    //     }
    //     this.currentSuppliers = clone(currentSuppliers.sort((a, b) => a.name.localeCompare(b.name)))
    //   }
    // }
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  async mounted () {
    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locations = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)

    var query = df.collection('suppliers').where('company_ids', 'array-contains', state.getCurrentCompany().id)

    query
      .onSnapshot((doc) => {
        this.suppliers = []
        doc.docs.forEach((rec) => {
          this.suppliers.push(rec.data())
        })
      })

    this.suppliers.sort((a, b) => a.name - b.name) // b - a for reverse sort

    query = df.collection('suppliers').where('editable', '==', false).orderBy('name', 'asc')

    query
      .onSnapshot((doc) => {
        this.globalSuppliers = []
        doc.docs.forEach((rec) => {
          var supp = rec.data()
          if (!supp.company_ids.includes(state.getCurrentCompany().id)) {
            this.globalSuppliers.push(supp)
          }
        })
      })

    this.globalSuppliers.sort((a, b) => a.name - b.name) // b - a for reverse sort
  },
  methods: {
    assignSupplier () {
      const that = this

      //   this.showLoader()
      this.submitting = true
      const reqId = uuidv4()
      const payload = {
        action: {
          operation: 'set',
          entity: 'supplier_assign',
          params: {
            company_id: state.getCurrentCompany().id,
            supplier_id: this.globalSupplier.id
          }
        }
      }

      try {
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'supplier',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
            // setTimeout(() => {
            that.globalSupplier = undefined
            that.showAssignSupplier = false
            // }, 100);
          })
          .catch(function (err) {
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.showAssignSupplier = false
          })
      } catch (err) {
        that.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.showAssignSupplier = false
      }
    },
    locationSelected () {
      var query = df.collection('suppliers').where('company_locations', 'array-contains', state.getCurrentCompany().id + '.' + this.location.id)

      query
        .onSnapshot((doc) => {
          this.suppliers = []
          doc.docs.forEach((rec) => {
            this.suppliers.push(rec.data())
          })
        })
    },
    openSupplierIntegrations (supplier) {
      this.$refs.supplierIntegrations.open(supplier, this.location)
      // this.$router.push({
      //   name: 'pointOfSale.suppliers.supplierIntegrations',
      //   params: {
      //     supplier: supplier,
      //     location: this.location
      //   }
      // })
    },
    openSupplierItems (supplier) {
      this.$refs.supplierItems.open(supplier, state.getPointOfSale().id)
      // this.$router.push({
      //   name: 'pointOfSale.suppliers.supplierItems',
      //   params: {
      //     supplier: supplier,
      //     location: this.location
      //   }
      // })
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    addSupplier () {
      this.$refs.addSupplier.open()
    },
    editSupplier (supplier) {
      this.$refs.editSupplier.open(supplier)
    },
    refreshSuppliers (supplier) {
      this.suppliers.push(
        { ...supplier, ...{ status: 'PROCESSING' } }
      )
      this.filter = supplier.name
    },
    refreshEditSuppliers (supplier) {
      this.filter = supplier.name
      this.suppliers = this.suppliers.map(_supplier => {
        if (_supplier.id === supplier.id) {
          _supplier.name = supplier.name
          _supplier.status = 'PROCESSING'
        }
        return _supplier
      })
    }
  }
}
</script>
