<template>
  <div>
    <confirm ref="confirmStorno" @selection="cancelReceipt" />
    <confirm ref="confirmFiscalize" @selection="fiscalizeReceipt" />
    <v-container>
      <v-row style="margin-top: 20px" class="d-flex justify-center">
        <v-col cols="4" sm="3" md="2" xl="1">
          <v-select
            v-model="reciptOffer"
            :items="reciptOfferChoices"
            item-text="name"
            item-value="value"
            @change="getReceiptsOrOffers"
          >
          </v-select>
        </v-col>
        <v-col cols="5" sm="4" md="3" xl="2">
          <v-select
            label="Status računa"
            v-model="statusFilter"
            :items="filters"
            return-object
            item-text="name"
            item-value="value"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
          </v-select>
        </v-col>
        <v-col v-if="unfiskReceipts.length > 0">
          <v-btn
            class="white--text rounded-card"
            max-width="100%"
            width="100%"
            x-large
            color="fiscButton"
            :loading="fiscalizing"
            @click="fiscalizeAll"
            id="fiscalizeBtn"
          >
            {{ $t("$vuetify.receipts.fiscalizing") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row style="margin-top: 20px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(receipt, i) in receipts"
              :key="i"
              hover
              tabindex="0"
            >
              <v-expansion-panel-header
                height="200"
                :class="[
                  receipt.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  receipt.fiscalize === true &&
                  receipt.zki !== '' &&
                  receipt.jir === '' &&
                  issueBefore(receipt.issue_date_time) > now
                    ? 'status-failed-exp'
                    : 'status-ok-exp',
                  receipt.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  receipt.fiscalize === true &&
                  receipt.zki !== '' &&
                  receipt.jir === '' &&
                  issueBefore(receipt.issue_date_time) < now
                    ? 'status-failed-expired'
                    : 'status-ok-exp',
                  receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  parseInt(receipt.payment_due) > now &&
                  receipt.payment.current_status !== 'PAID'
                    ? 'status-warn-exp'
                    : '',
                  receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  parseInt(receipt.payment_due) < now &&
                  receipt.payment.current_status !== 'PAID'
                    ? 'status-warn-after-exp'
                    : '',
                  receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  receipt.payment.current_status === 'PAID'
                    ? 'status-ok-exp'
                    : '',
                  receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag === 'P'
                    ? 'status-ok-exp'
                    : '',
                ]"
                hide-actions
              >
                <v-row>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      {{
                        reciptOffer === "receipts"
                          ? $t("$vuetify.receipts.receiptNumber")
                          : $t("$vuetify.receipts.offerNumber")
                      }}
                    </div>
                    <div class="text-center">{{ receipt.receiptNumber }}</div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    style="min-width: 100px; max-width: 100%"
                    :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']"
                  >
                    <div class="caption grey--text">
                      {{ $t("$vuetify.receipts.customer") }}
                    </div>
                    <div class="text-capitalize">{{ receipt.customer }}</div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding, 'flex-shrink-0']"
                  >
                    <div class="caption grey--text text-right">
                      {{
                        reciptOffer === "receipts"
                          ? $t("$vuetify.receipts.receiptPrice")
                          : $t("$vuetify.receipts.offerPrice")
                      }}
                    </div>
                    <div class="text-right">
                      {{ receipt.total | money(100, receipt.currency) }}
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      {{ $t("$vuetify.receipts.receiptIssueDate") }}
                    </div>
                    <div class="text-center">
                      {{ receipt.issue_date_time | local }}
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      {{ $t("$vuetify.receipts.receiptPaymentDue") }}
                    </div>
                    <div class="text-center">
                      {{ receipt.payment_due | local }}
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="d-flex justify-space-between">
                      <div>
                        <div class="caption grey--text text-left">
                          {{ $t("$vuetify.receipts.status") }}
                        </div>
                        <div class="text-left">
                          {{
                            $t(
                              `$vuetify.receipts.${
                                receipt.payment
                                  ? receipt.payment.current_status
                                  : "UNKNOWN_STATUS"
                              }`
                            )
                          }}
                        </div>
                      </div>
                      <div class="d-flex justify-end align-center pl-2">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @keypress.enter.stop=""
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item
                              v-for="(item, index) in receipt.receiptOptions"
                              :key="index"
                              style="cursor: pointer"
                              @click="`${item.action(receipt)}`"
                              @keypress.enter.prevent="
                                `${item.action(receipt)}`
                              "
                              :disabled="
                                receipt.payment &&
                                receipt.payment.current_status === 'PROCESSING'
                              "
                            >
                              <v-list-item-title>{{
                                item.title
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="5">
                    <div
                      class="
                        d-flex
                        justify-space-between
                        text-center text-subtitle-2
                      "
                    >
                      Operater: {{ receipt.seller_id }}
                    </div>
                    <div
                      class="
                        d-flex
                        justify-space-between
                        text-center text-subtitle-2
                      "
                    >
                      Način plaćanja: {{ receipt.payment_method }}
                    </div>
                  </v-col>
                </v-row>
                <v-list>
                  <v-row>
                    <v-col
                      ><div
                        class="
                          text-center text-subtitle-2
                          one-liner-text
                          font-weight-bold
                        "
                      >
                        R. br.
                      </div></v-col
                    >
                    <v-col>
                      <div
                        class="
                          text-center text-center text-subtitle-2
                          one-liner-text
                          font-weight-bold
                        "
                      >
                        Naziv
                      </div></v-col
                    >
                    <v-col>
                      <div
                        class="
                          text-center text-center text-subtitle-2
                          one-liner-text
                          font-weight-bold
                        "
                      >
                        Količina
                      </div>
                    </v-col>
                    <v-col
                      ><div
                        class="
                          text-center text-center text-subtitle-2
                          one-liner-text
                          font-weight-bold
                        "
                      >
                        Jedinična cijena
                      </div></v-col
                    >
                    <v-col
                      ><div
                        class="
                          text-center text-center text-subtitle-2
                          one-liner-text
                          font-weight-bold
                        "
                      >
                        Cijena
                      </div></v-col
                    >
                  </v-row>
                  <v-list-item
                    v-for="(item, index) in receipt.items"
                    :key="index"
                    class="pa-0"
                  >
                    <v-row class="pa-0">
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ index + 1 }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.name }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.amt / multiplier }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{
                            item.single_item_price
                              | money(100, receipt.currency)
                          }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.price | money(100, receipt.currency) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <generate-receipt-from-offer ref="receiptFromOffer" />
      <send-email ref="ordinaryEmail" @receiptEdit="onReceiptEdit"></send-email>
      <send-email ref="clientWarning" @receiptEdit="onReceiptEdit"></send-email>
      <pay-receipt ref="payReceipt" @receiptEdit="onReceiptEdit" />
      <status-to-unpaid
        ref="statusToUnpaid"
        @receiptEdit="onReceiptEdit"
      ></status-to-unpaid>
    </v-container>
  </div>
</template>
<script>
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import InfiniteLoading from 'vue-infinite-loading'
import Confirm from '@/components/Confirm.vue'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import { clone, toISOLocal } from '@/plugins/utils'
import GenerateReceiptFromOffer from '@/modules/company/components/GenerateReceiptFromOffer'
import PayReceipt from '@/modules/company/components/PayReceipt'
import SendEmail from '@/modules/company/components/SendEmail'
import StatusToUnpaid from '@/modules/company/components/StatusToUnpaid'

export default {
  components: {
    InfiniteLoading,
    Confirm,
    GenerateReceiptFromOffer,
    PayReceipt,
    SendEmail,
    StatusToUnpaid
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      receipts: [],
      unfiskReceipts: [],
      location: {},
      locations: [],
      statusFilter: {},
      filterChoosen: '',
      loadingReceipts: false,
      lastVisible: {},
      isExecuted: false,
      listeners: [],
      increment: 1,
      selected: {},
      reciptOffer: {},
      multiplier: 0,
      show: false,
      fiscalizing: false,
      aduroId: undefined
    }
  },
  computed: {
    reciptOfferChoices () {
      return [
        { name: this.$tc('$vuetify.receipt', 2), value: 'receipts' },
        { name: this.$tc('$vuetify.offer', 2), value: 'offers' }
      ]
    },
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    locs: {
      get: function () {
        return this.locations
      },
      set: function (nv) {
        this.locations = [
          { name: this.$t('$vuetify.all'), id: '', location_id: '' }
        ].concat(nv)
      }
    },
    filters () {
      if (this.reciptOffer === 'receipts') {
        return [
          { name: 'Plaćeni', value: 'payed' },
          { name: 'Neplaćeni', value: 'unpayed' },
          { name: 'Svi', value: 'all' }
        ]
      } else {
        return [
          { name: 'Svi', value: 'all' }
        ]
      }
    }
  },
  watch: {
    location: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
          this.receipts = []
          this.unfiskReceipts = []
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    statusFilter: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
          this.receipts = []
          this.unfiskReceipts = []
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
  },
  mounted () {
    this.getMultiplier()
    this.locations = [
      { name: this.$t('$vuetify.all'), id: '', location_id: '' }
    ]
    this.location = this.locations[0]

    this.reciptOffer = this.reciptOfferChoices[0].value

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locs = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)
  },
  methods: {
    issueBefore (issueDateTime) {
      const tmp = new Date(
        toISOLocal(
          new Date(issueDateTime * 1000)
        )
      )

      tmp.setDate(tmp.getDate() + 2)

      const before = parseInt(
        (tmp.getTime() / 1000).toFixed(0)
      )

      return before
    },
    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    fiscalizeAll () {
      const that = this
      try {
        this.selected = {}
        const reqId = uuidv4()

        const payload = {
          action: {
            operation: 'update',
            entity: 'fiscalize_bulk',
            resource_id: '',
            params: {
              company_id: state.getCurrentCompany().id
            }
          }
        }

        that.showLoader()
        that.$refs.confirmFiscalize.close()
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'receipt',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
          })
          .catch(function (err) {
            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            unsubscribe()
            if (!doc.data().header || !doc.data().header.code || doc.data().header.code !== 200) {
              that.showMsgBox({
                text: doc.data().header.error ? doc.data().header.error : 'Nije uspjela fiskalizacija računa!',
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              that.lastVisible = {}
              that.receipts = []
              that.unfiskReceipts = []
              that.$refs.InfiniteLoading.stateChanger.reset()
              that.$refs.confirmFiscalize.close()
              return
            }

            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
          }
        })
      } catch (err) {
        that.hideLoader()

        that.lastVisible = {}
        that.receipts = []
        that.unfiskReceipts = []
        that.$refs.InfiniteLoading.stateChanger.reset()
        that.$refs.confirmFiscalize.close()
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    modifyItems (change) {
      if (change.type === 'added') {
        if (change.doc.data()) {
          const _document = clone(change.doc.data())
          switch (_document.type.flag) {
            case 'R':
            case 'R1':
            case 'R2':
            case 'BO':
              _document.receiptOptions = [
                { title: 'Preuzmi račun', action: this.printReceipt }
              ]
              break
            case 'STORNO':
              _document.receiptOptions = [
                { title: 'Preuzmi račun', action: this.printReceipt }
              ]
              break
            case 'P':
              _document.receiptOptions = [
                { title: 'Preuzmi ponudu', action: this.printReceipt }
              ]
          }

          let rcptSeparator = '-'
          if (_document.company_details.rcpt_separator !== undefined && _document.company_details.rcpt_separator !== '') {
            rcptSeparator = _document.company_details.rcpt_separator
          }

          if (_document.designation) {
            _document.receiptNumber = `${_document.designation.number}${rcptSeparator}${_document.designation.location_id}${rcptSeparator}${_document.designation.register_number}`
          }
          if (_document.issue_date_time) {
            _document.time = _document.issue_date_time.seconds
          }
          if (_document?.type?.flag !== 'BO' && _document?.type?.payer_name) {
            _document.customer = _document.type.payer_name
          } else {
            _document.customer = this.$t('$vuetify.receipts.retailCustomer')
          }

          _document.show = false

          if (
            this.receipts.length > 0 &&
            change.doc.data().issue_date_time >
              this.receipts[this.receipts.length - 1].issue_date_time
          ) {
            this.receipts.unshift({
              ..._document,
              ...{ [`hover-${change.doc.data().id}`]: false }
            })
          } else {
            this.receipts.push({
              ..._document,
              ...{ [`hover-${change.doc.data().id}`]: false }
            })
          }
        }
      }
      if (change.type === 'modified') {
        // TODO: duplicated code, make 1 fun
        this.receipts = this.receipts.map(item => {
          let ret = item
          if (item.id === change.doc.data().id) {
            const _document = clone(change.doc.data())
            switch (_document.type.flag) {
              case 'R':
              case 'R1':
              case 'R2':
              case 'BO':
                _document.receiptOptions = [
                  { title: 'Preuzmi račun', action: this.printReceipt }
                ]
                break
              case 'STORNO':
                _document.receiptOptions = [
                  { title: 'Preuzmi račun', action: this.printReceipt }
                ]
                break
              case 'P':
                _document.receiptOptions = [
                  { title: 'Preuzmi ponudu', action: this.printReceipt }
                ]
            }
            if (_document.designation) {
              _document.receiptNumber = `${_document.designation.number}-${_document.designation.location_id}-${_document.designation.register_number}`
            }
            if (_document.issue_date_time) {
              _document.time = _document.issue_date_time.seconds
            }
            if (_document?.type?.flag !== 'BO' && _document?.type?.payer_name) {
              _document.customer = _document.type.payer_name
            } else {
              _document.customer = this.$t('$vuetify.receipts.retailCustomer')
            }
            ret = _document
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }

      this.receipts = this.receipts.filter((document, index, self) =>
        index === self.findIndex((t) => (
          t.id === document.id
        ))
      )
    },
    async infiniteHandler ($state) {
      const aduroId = await df.doc('project_constants/aikasa_config/').get()
      this.aduroId = aduroId.data().aduro_company_id
      const currentCompanyOib = state.getCurrentCompany().oib

      const that = this
      const where = null
      const whereFilter = that.statusFilter ? that.statusFilter.value : ''
      let query = df
        .collection(
          `${this.reciptOffer}/${this.aduroId}/${
            this.reciptOffer
          }`
        )
        .where('type.payer_oib', '==', currentCompanyOib)
        .orderBy('issue_date_time', 'desc')
        .limit(10)
        .startAfter(that.lastVisible)

      if (where) {
        query = query.where('designation.location_id', '==', where)
      }

      if (whereFilter === 'unfisc') {
        query = query.where('fiscalize', '==', true).where('jir', '==', '')
      }

      if (whereFilter === 'payed') {
        query = query.where('payment.current_status', '==', 'PAID')
      }

      if (whereFilter === 'unpayed') {
        query = query.where('payment.current_status', '==', 'UNPAID')
      }

      const listener = query.onSnapshot(
        doc => {
          doc.docChanges().forEach(change => {
            that.modifyItems(change)
          })
          if (doc && !doc.empty) {
            if (doc.docs[doc.docs.length - 1]) {
              that.lastVisible = doc.docs[doc.docs.length - 1]
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        }
      )

      this.listeners.push(listener)
    },
    getReceiptsOrOffers () {
      this.lastVisible = ''
      this.receipts = []
      this.unfiskReceipts = []
      this.detachListeners()

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    printReceipt (receipt, preview = false) {
      const that = this
      try {
        that.showLoader()
        const reqId = uuidv4()
        let entity = 'receipt'
        if (this.reciptOffer === 'offers') {
          entity = 'offer'
        }

        const payload = {
          action: {
            operation: 'get',
            entity: entity,
            resource_id: receipt.id,
            params: {
              company_id: this.aduroId,
              print_size: state.getPrinter()
            }
          }
        }

        if (that.reciptOffer === 'offers') {
          payload.action.entity = 'offer'
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'document',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          })
          .then(function () {})
          .catch(function (err) {
            that.hideLoader()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            // this.$refs.createBusinessForm.reset()
            unsubscribe()
            if (!doc.data().body || !doc.data().body.document) {
              that.showMsgBox({
                text: that.$t('$vuetify.errors.nullReceipt'),
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              return
            }

            if (preview) {
              that.openPdfPrint(doc.data().body.document)
              that.hideLoader()
            } else {
              const linkSource = `data:application/pdf;base64,${
                doc.data().body.document
              }`
              const downloadLink = document.createElement('a')
              const fileName = `${receipt.receiptNumber}.pdf`
              downloadLink.href = linkSource
              downloadLink.download = fileName
              downloadLink.click()
              that.hideLoader()
            }
          }
        })
      } catch (err) {
        that.hideLoader()
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    cancelReceipt () {
      const that = this
      try {
        const receipt = Object.assign({}, this.selected)
        this.selected = {}
        const reqId = uuidv4()

        let receiptNumber = receipt.designation ? receipt.designation.number : 0
        df.doc(`cash_registers/${receipt.designation.register_id}`)
          .get()
          .then(reg => {
            if (reg && reg.exists) {
              if (reg.data()) {
                if (reg.data().status !== 'OK') {
                  this.$refs.confirmStorno.close()
                  that.showMsgBox({
                    text: that.$t('$vuetify.errors.unknownRegister'),
                    actions: ['cancel'],
                    cancelBtnText: that.$t('$vuetify.close'),
                    color: 'error'
                  })
                  return
                }
                if (this.reciptOffer === 'receipts') {
                  receiptNumber = reg.data().bill_sequence || 0
                } else if (this.reciptOffer === 'offers') {
                  receiptNumber = reg.data().offer_sequence || 0
                }
                const payload = {
                  action: {
                    operation: 'set',
                    entity: 'storno',
                    params: {
                      company_id: state.getCurrentCompany().id,
                      location_id: receipt.location_details.id,
                      receipt_id: receipt.id,
                      register_id: receipt.designation.register_id,
                      issue_date_time: parseInt(
                        (new Date().getTime() / 1000).toFixed(0)
                      ),
                      number: receiptNumber + 1
                      // seller_id: auth.currentUser.uid,
                    }
                  }
                }

                that.showLoader()
                that.$refs.confirmStorno.close()
                df.doc(`request/${reqId}`)
                  .set({
                    user_id: `${auth.currentUser.uid}`,
                    device_id: 'web',
                    created: `${new Date().getTime()}`,
                    type: 'receipt',
                    payload: btoa(
                      unescape(encodeURIComponent(JSON.stringify(payload)))
                    )
                  })
                  .then(function () {
                    setTimeout(() => {
                      that.hideLoader()
                      that.lastVisible = {}
                      that.receipts = []
                      that.unfiskReceipts = []
                      that.$refs.InfiniteLoading.stateChanger.reset()
                    }, 5500)
                  })
                  .catch(function (err) {
                    that.hideLoader()
                    that.showMsgBox({
                      text: err && err !== '' ? err : 'An error has occurred',
                      actions: ['cancel'],
                      cancelBtnText: 'OK',
                      color: 'error'
                    })
                  })
              }
            } else {
              that.showMsgBox({
                text: that.$t('$vuetify.errors.unknownRegister'),
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              this.$refs.confirmStorno.close()
            }
          })
      } catch (err) {
        this.$refs.confirmStorno.close()
        that.hideLoader()
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    generateReceipt (receipt) {
      this.$refs.receiptFromOffer.open(receipt, this.multiplier)
    },
    payReceipt (receipt) {
      this.$refs.payReceipt.open(receipt)
    },
    onReceiptEdit (receipt) {
      this.receipts = this.receipts.map(r => {
        if (r.id === receipt.id) {
          r.payment.current_status = 'PROCESSING'
        }
        return r
      })
    },
    statusToUnpaid (receipt) {
      this.$refs.statusToUnpaid.open(receipt)
    },
    fiscalizeReceipt () {
      const that = this
      try {
        const receipt = Object.assign({}, this.selected)
        this.selected = {}
        const reqId = uuidv4()

        const payload = {
          action: {
            operation: 'update',
            entity: 'fiscalize',
            resource_id: receipt.id,
            params: {
              company_id: state.getCurrentCompany().id
            }
          }
        }

        that.showLoader()
        that.$refs.confirmFiscalize.close()
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'receipt',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
          })
          .catch(function (err) {
            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            unsubscribe()
            if (!doc.data().header || !doc.data().header.code || doc.data().header.code !== 200) {
              that.showMsgBox({
                text: doc.data().header.error ? doc.data().header.error : 'Nije uspjela fiskalizacija računa!',
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              that.lastVisible = {}
              that.receipts = []
              that.unfiskReceipts = []
              that.$refs.InfiniteLoading.stateChanger.reset()
              that.$refs.confirmFiscalize.close()
              return
            }

            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
          }
        })
      } catch (err) {
        that.hideLoader()

        that.lastVisible = {}
        that.receipts = []
        that.unfiskReceipts = []
        that.$refs.InfiniteLoading.stateChanger.reset()
        that.$refs.confirmFiscalize.close()
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
</style>
