<template>
  <div id="app">
    <v-container fluid :style="`margin-left: ${margin}`">
      <v-row
        align="center"
        class="d-flex justify-center"
        style="margin-top: 20px"
      >
        <v-col class="d-flex justify-center" cols="12" sm="6">
          <v-select
            v-model="selectedCashRegister"
            :items="currentlyActiveCashRegisterDocuments"
            label="Blagajne"
            item-text="name"
            item-value="id"
            dense
            @change="showData"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-if="showClosingBalanceList">
      <v-card flat class="product-container">
        <v-card-text>
          <v-row>
            <v-col cols="9" md="6" lg="2" xl="2">
              <v-menu
                offset-y
                v-model="dateSearchMenu"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on"
                    v-bind="attrs"
                    clearable
                    @click:clear="showData(true)"
                    :value="closingBalanceStructure.view"
                    :label="$t('$vuetify.cashRegister.closingBalanceSearch')"
                    dense
                    autocomplete="off"
                    prepend-icon="mdi-clock-time-four-outline"
                    @click:prepend="dateSearchMenu = !dateSearchMenu"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="closingBalanceDate"
                  locale="hr"
                  no-title
                  scrollable
                  @input="dateSearchMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-expansion-panels v-if="balances && balances.length > 0">
            <v-expansion-panel v-for="(item, i) in balances" :key="i">
              <v-expansion-panel-header>
                <v-row>
                  <v-col>
                    <span>Blagajnu zaključio: </span>
                    <div class="mt-2 font-weight-bold">
                      {{
                        $options.filters.capitalize(
                          item.balanceClosingRequestedBy
                        )
                      }}
                    </div>
                  </v-col>
                  <v-col>
                    <span>Dana: </span
                    ><span class="font-weight-bold">{{
                      item.to | fullDateTime
                    }}</span>
                  </v-col>
                  <v-col>
                    <span>Utržak: </span
                    ><span class="font-weight-bold">{{
                      item.to > 1672527599
                        ? $options.filters.money(
                            item.available_funds,
                            100,
                            "EUR"
                          )
                        : $options.filters.money(
                            item.available_funds,
                            100,
                            "HRK"
                          )
                    }}</span>
                  </v-col>
                  <div style="width: 5%; padding-top: 10px">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @keypress.enter.stop=""
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(it, index) in item.options"
                          :key="index"
                          style="cursor: pointer"
                          @click="`${it.action(item.printRequestData)}`"
                          @keypress.enter.prevent="
                            `${it.action(item.printRequestData)}`
                          "
                        >
                          <v-list-item-title>{{ it.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- <span>Preuzmi </span
                  ><a
                    href=""
                    @click.prevent.stop="
                      downloadClosingBalance(item.printRequestData)
                    "
                    >zaključak</a
                  > -->
                  </div>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- <v-row>
                <v-col>
                  <div>
                    Najveća količina novca na blagajni u trenutku izdavanja zaključka: <span class="font-weight-bold">{{item.maxHoldingAmt  | money(100, currency)}}</span>
                  </div>
                </v-col>
              </v-row> -->
                <v-row>
                  <v-col>
                    <div>
                      Novčanice:
                      <span class="font-weight-bold">{{
                        item.to > 1672527599
                          ? $options.filters.money(
                              item.available_funds,
                              100,
                              "EUR"
                            )
                          : $options.filters.money(
                              item.available_funds,
                              100,
                              "HRK"
                            )
                      }}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div>
                      Kartice:
                      <span class="font-weight-bold">{{
                        item.to > 1672527599
                          ? $options.filters.money(item.card_funds, 100, "EUR")
                          : $options.filters.money(item.card_funds, 100, "HRK")
                      }}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div>
                      Ostalo:
                      <span class="font-weight-bold">{{
                        item.to > 1672527599
                          ? $options.filters.money(item.other_funds, 100, "EUR")
                          : $options.filters.money(item.other_funds, 100, "HRK")
                      }}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div>
                      Polog na blagajni u trenutku izdavanja zaključka:
                      <span class="font-weight-bold">{{
                        item.to > 1672527599
                          ? $options.filters.money(item.depositAmt, 100, "EUR")
                          : $options.filters.money(item.depositAmt, 100, "HRK")
                      }}</span>
                    </div>
                  </v-col>
                </v-row>
                <!-- <v-row>
                <v-col>
                  <div>
                    Ukupan preostali iznos u trenutku izdavanja zaključka: <span class="font-weight-bold">{{item.cashLeft  | money(100, currency)}}</span>
                  </div>
                </v-col>
              </v-row> -->
                <v-row>
                  <v-col>
                    <div>
                      Datum prethodnog zaključka:
                      <span class="font-weight-bold">{{
                        item.from | fullDateTime
                      }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row v-else>
            <p
              class="text--secondary"
              style="margin-top: 50px; margin-left: 50px"
            >
              Ne postoje zaključci po odabranim kriterijama
            </p>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import applicationSettings from '@/mixins/applicationSettings'
import { toISOLocal, duck, clone } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import { closingBalance } from '@/libs/closingBalance/closingBalance.js'

export default {
  data () {
    return {
      currentlyActiveCashRegisterDocuments: [],
      selectedCashRegister: undefined,
      showClosingBalanceList: false,
      selectedDateStartTime: 0,
      selectedDateEndTime: 0,
      balances: [],
      closingBalanceDate: '',
      dateSearchMenu: false,
      currency: 'EUR',
      selectedPrinter: {}
    }
  },
  created () {
    this.margin = state.getMargin()
  },
  mounted () {
    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })
    this.selectedPrinter = state.getSelectedPrinter()
    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
    })
    this.getCurrentlyActiveCashRegisterDocuments()

    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
  },
  inject: ['showMsgBox', 'openPdfPrint', 'showLoader', 'hideLoader'],
  mixins: [applicationSettings],
  computed: {
    closingBalanceStructure () {
      if (!this.closingBalanceDate) return {}
      return {
        view: new Date(
          toISOLocal(
            new Date(new Date(this.closingBalanceDate).getTime())
          ).substr(0, 10)
        ).toLocaleDateString('hr-HR'),
        from: parseInt(
          (
            new Date(`${this.closingBalanceDate}T00:00:00`).getTime() / 1000
          ).toFixed(0)
        ),
        to: parseInt(
          (
            new Date(`${this.closingBalanceDate}T23:59:59`).getTime() / 1000
          ).toFixed(0)
        )
      }
    }
  },
  watch: {
    closingBalanceStructure: {
      deep: true,
      handler: function () {
        this.showData()
      }
    }
  },
  beforeDestroy () {
    this.detachListeners()
  },
  methods: {
    async getCurrentlyActiveCashRegisterDocuments () {
      const snapshot = await df.collection('cash_registers')
        .where('status', '==', 'OK')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('location_id', '==', state.getPointOfSale().id)
        .get()

      const documentSnapshot = snapshot.docs.map(doc => doc.data())
      const currentlyActiveCashRegisterDocuments = []

      documentSnapshot.forEach(document => currentlyActiveCashRegisterDocuments.push(document))
      this.currentlyActiveCashRegisterDocuments = clone(currentlyActiveCashRegisterDocuments.sort((a, b) => a.name.localeCompare(b.name)))
    },
    showData (reset = false) {
      this.showClosingBalanceList = true

      if (this.selectedCashRegister !== undefined) {
        let query = df
          .collection(`cash_registers/${this.selectedCashRegister}/balances`)
          .orderBy('to', 'desc')
          .limit(10)

        if (!duck(this.closingBalanceStructure, {}) && !reset) {
          query = query
            .where('to', '>=', this.closingBalanceStructure.from)
            .where('to', '<=', this.closingBalanceStructure.to)
        }

        this.$emit('setPrintState', true)
        query
          .get()
          .then((doc) => {
            const document = doc
            this.balances = []
            if (!document || document.empty) return
            document.docs.forEach((doc) => {
              const docData = doc.data()
              if (!docData) return
              docData.balanceClosingRequestedBy = `${
              docData.requested_by?.name || ''
            } ${docData.requested_by?.surname || ''}`
              docData.available_funds =
              docData.cash_register?.available_funds || 0
              docData.other_funds =
              docData.cash_register?.other_funds || 0
              docData.card_funds = docData.cash_register?.card_funds || 0
              docData.cashLeft =
              (docData.cash_register?.max_holding_amt || 0) -
              docData.available_funds -
              (docData.deposit?.amt || 0)
              docData.depositAmt = docData.deposit?.amt || 0
              docData.maxHoldingAmt = docData.cash_register?.max_holding_amt || 0
              if (docData.cash_register) {
                docData.printRequestData = {
                  action: {
                    operation: 'get',
                    entity: 'cashier_balance',
                    resource_id: docData.id,
                    params: {
                      cash_register_id: docData.cash_register.id,
                      company_id: state.getCurrentCompany().id,
                      print_receipts: true,
                      print_size: state.getPrinter()
                    }
                  }
                }
              }
              docData.options = [{ title: 'Preuzmi zaključak', action: this.downloadClosingBalance },
                { title: 'Isprintaj zaključak', action: this.printClosingBalance }
              ]
              this.balances.push(docData)
            })
          })
          .finally(() => {
            this.$emit('setPrintState', false)
          })
      }
    },
    printClosingBalance (payload = undefined) {
      this.$emit('setPrintState', true)
      if (['T58', 'T80', 'A4'].includes(this.selectedPrinter.width)) {
        this.showMsgBox({
          text: 'Nije odabran printer',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      } else {
        this.printLocal(payload.action.resource_id)
      }
    },
    downloadClosingBalance (payload = undefined) {
      this.showLoader()
      if (!payload) {
        this.showMsgBox({
          text: 'Ne postoje podaci o zaključku',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
        return
      }
      try {
        const reqId = uuidv4()
        payload.action.params = {
          ...payload.action.params,
          print_size: state.getPrinter()
        }
        this.$emit('setPrintState', true)

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'document',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .catch((err) => {
            this.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          })
        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot((doc) => {
          const data = doc.data()
          if (data) {
            unsubscribe()
            this.$emit('setPrintState', false)

            if (!data.body || !data.body.document) {
              this.showMsgBox({
                text: this.$t('$vuetify.errors.nullClosingBalance'),
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })
              return
            }
            this.openPdfPrint(data.body.document)
            this.hideLoader()
          }
        })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    async printLocal (balanceId) {
      const response = await df.doc(`cash_registers/${state.getCashRegister().id}/balances/${balanceId}`).get()
      if (response && response.data()) {
        var closeBalance = response.data()
        closingBalance(closeBalance, this.selectedPrinter)
        this.$emit('setPrintState', false)
      }
    }
  }
}
</script>

<style>
</style>
