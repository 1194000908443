<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addItemForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Artikl</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row>
                <v-col>
                  <v-textarea
                    class="pt-2"
                    rows="2"
                    dense
                    outlined
                    label="Naziv"
                    autofocus
                    v-model="name"
                    :rules="[rules.req]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field dense outlined label="Barkod" v-model="barcode">
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    outlined
                    label="Šifra"
                    v-model="code"
                    @input="checkCodeAvailability"
                    validate-on-blur
                    :rules="[rules.codeused]"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    dense
                    outlined
                    label="Vrsta artikla"
                    v-model="type"
                    item-text="name"
                    return-object
                    :items="itemTypes"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="!this.warehouseModule">
                <v-col v-if="!this.warehouseModule">
                  <v-select
                    v-if="!this.warehouseModule"
                    v-model="selectedLocations"
                    :items="locations"
                    label="Omogući prodaju na prodajnom mjestu"
                    multiple
                    outlined
                    dense
                    clearable
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-if="!this.warehouseModule"
                    dense
                    outlined
                    label="Skladištna jedinica"
                    v-model="storageUnit"
                    item-text="name"
                    return-object
                    :items="storageUnits"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-if="!this.warehouseModule"
                    dense
                    outlined
                    label="Prodajna jedinica"
                    v-model="sellingUnit"
                    item-text="name"
                    return-object
                    :items="sellingUnits"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-text-field
                  class="pa-2"
                  v-if="warehouseModule"
                  outlined
                  v-model="whStorageUnit"
                  label="Osnovna jedinica"
                >
                </v-text-field>
                <v-checkbox
                  class="pa-2"
                  v-model="whSellingItem"
                  outlined
                  v-if="warehouseModule"
                  label="Prodajna jedinica"
                >
                </v-checkbox>
              </v-row>
              <v-row class="pa-0">
                <v-col class="d-flex justify-center">
                  <v-checkbox
                    v-model="whDefaultSale"
                    class="pa-2"
                    outlined
                    v-if="warehouseModule"
                    @change="changeDefaultSale(whDefaultSale)"
                    label="Zadana prodajna jedinica"
                  >
                  </v-checkbox>
                  <v-checkbox
                    v-model="whDefaultStore"
                    class="pa-2"
                    outlined
                    v-if="warehouseModule"
                    @change="changeDefaultStore(whDefaultStore)"
                    label="Zadana skladišna jedinica"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-col v-if="newUnits.length > 0">
                <v-flex v-for="(unit, idx) in newUnits" :key="idx">
                  <v-row class="pa-10">
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        v-model="unit.customItemUnit"
                        v-if="newUnits.length > 0"
                        label="Jedinica mjere"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <quantity-input
                        outlined
                        v-if="newUnits.length > 0"
                        label="Količina"
                        v-model="unit.customItemAmount"
                      ></quantity-input>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        outlined
                        v-if="newUnits.length > 0"
                        :value="whStorageUnit"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-checkbox
                        outlined
                        width="20%"
                        v-if="newUnits.length > 0"
                        v-model="unit.sellingItem"
                        label="Prodajna jedinica"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-row class="pa-0">
                      <v-col class="d-flex justify-center">
                        <v-checkbox
                          class="pa-2"
                          outlined
                          v-if="newUnits.length > 0"
                          v-model="unit.defaultSale"
                          label="Zadana prodajna jedinica"
                          @change="
                            changeDefaultSaleCustom(unit.defaultSale, idx)
                          "
                        >
                        </v-checkbox>
                        <v-checkbox
                          class="pa-2"
                          outlined
                          v-if="newUnits.length > 0"
                          v-model="unit.defaultStore"
                          @change="
                            changeDefaultStoreCustom(unit.defaultStore, idx)
                          "
                          label="Zadana skladišna jedinica"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-divider></v-divider>
                </v-flex>
              </v-col>
              <v-row class="pa-5">
                <v-btn
                  class="white--text okButton"
                  height="45"
                  v-if="this.warehouseModule"
                  color="grey"
                  dark
                  @keyup.enter="addMoreUnits"
                  @click.stop="addMoreUnits"
                  >Dodaj jedinicu</v-btn
                >
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-row class="justify-center pa-0 ma-0">
                <v-col class="justify-center" cols="3">
                  <v-img
                    ref="itemImg"
                    :src="itemImg"
                    contain
                    height="123px"
                    width="124px"
                    :lazy-src="
                      itemImg ? require('@/assets/no-item-small.jpg') : ''
                    "
                  >
                    <template v-slot:placeholder>
                      <v-img
                        :src="require('@/assets/no-item-small.jpg')"
                      ></v-img>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="6">
                  <v-file-input
                    ref="imageUpl"
                    label="Odaberi sliku artikla"
                    style="overflow: hidden"
                    @change="onFileChange"
                    clearable
                    @click:close="onFileChange"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting || isExistingCode"
            type="submit"
          >
            Dodaj artikl
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df, auth, functions } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
import QuantityInput from '../../../components/QuantityInput.vue'

export default {
  components: { QuantityInput },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    submitting: false,
    name: undefined,
    code: undefined,
    barcode: undefined,
    type: undefined,
    itemTypes: [],
    newUnits: [],
    storeUnit: undefined,
    storageUnit: undefined,
    storageUnits: [],
    sellingUnit: undefined,
    sellingUnits: [],
    checking: false,
    isExistingCode: false,
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    selectedLocations: [],
    locations: [],
    itemImg: '',
    warehouseModule: false,
    whStorageUnit: undefined,
    whSellingItem: undefined,
    whDefaultSale: undefined,
    whDefaultStore: undefined,
    listeners: []
  }),
  mounted () {
    this.getMultiplier()
    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }

    this.rules.codeused = (val) => {
      return (
        !this.isExistingCode ||
        `Artikl sa šifrom ${val} već postoji.`
      )
    }
    this.itemTypes = [
      { name: this.$t('$vuetify.lager.itemTypes.goods'), value: 'GOODS' },
      { name: this.$t('$vuetify.lager.itemTypes.service'), value: 'SERVICE' }
    ]

    this.storageUnits = [
      { name: 'Komad', value: 'komad' },
      { name: 'Kilogram', value: 'kilogram' },
      { name: 'Litra', value: 'litra' },
      { name: 'Sat', value: 'sat' },
      { name: 'm2', value: 'm2' },
      { name: 'm', value: 'm' }
    ]

    this.sellingUnits = [
      { name: 'Komad', value: 'komad' },
      { name: 'Kilogram', value: 'kilogram' },
      { name: 'Litra', value: 'litra' },
      { name: 'Sat', value: 'sat' },
      { name: 'm2', value: 'm2' },
      { name: 'm', value: 'm' }
    ]
  },
  methods: {
    changeDefaultSaleCustom (value, index) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            if (key !== index) {
              this.newUnits[key].defaultSale = false
            }
          })
        }
        this.whDefaultSale = false
      }
    },
    changeDefaultStoreCustom (value, index) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            if (key !== index) {
              this.newUnits[key].defaultStore = false
            }
          })
        }
        this.whDefaultStore = false
      }
    },
    changeDefaultSale (value) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            this.newUnits[key].defaultSale = false
          })
        }
      }
    },
    changeDefaultStore (value) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            this.newUnits[key].defaultStore = false
          })
        }
      }
    },
    addMoreUnits () {
      const i = this.newUnits.length + 1
      this.newUnits.push({ name: 'Mjerna jedinica', value: i })
    },
    async checkCodeAvailability () {
      const code = this.code
      this.checking = true
      if (this.code !== undefined && this.code !== '' && this.code !== null) {
        const isExistingCode = await df
          .collection(`companies/${state.getCurrentCompany().id}/items`)
          .where('code', '==', code)
          .where('status', '==', 'OK')
          .get()

        this.isExistingCode = !isExistingCode.empty
      } else {
        this.isExistingCode = false
      }
      this.checking = false
    },
    open () {
      this.visible = true
      this.type = this.itemTypes[0]
      this.storageUnit = this.storageUnits[0]
      this.sellingUnit = this.sellingUnits[0]
      this.submitting = false

      df.doc(
        `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
      )
        .get()
        .then((doc) => {
          if (
            doc.data() &&
            doc.data().locations &&
            Object.keys(doc.data().locations).length > 0
          ) {
            this.locations = Object.keys(doc.data().locations)
              .map((key) => {
                return doc.data().locations[key]
              })
              .filter((location) => location.status === 'OK')

            this.selectedLocations = this.locations.map((location) => {
              return location.id
            })
          }
        })
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.itemImg = ''
      this.name = undefined
      this.code = undefined
      this.barcode = undefined
      this.visible = false
      this.newUnits = []
      this.$refs.imageUpl.reset()
    },
    createImage (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.itemImg = e.target.result
      }
      reader.readAsDataURL(file)
    },
    onFileChange (file) {
      if (!file) {
        this.itemImg = ''
        return
      }
      if (file.size > 500000) {
        this.showMsgBox({
          text: 'Prevelika datoteka, maksimalna dopuštena veličina datoteke je 500 KB.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.itemImg = ''
        this.$refs.imageUpl.reset()
        return
      }
      this.createImage(file)
    },
    submit () {
      if (!this.$refs.addItemForm.validate()) return
      //   this.showLoader()
      this.submitting = true

      this.itemImg =
        this.itemImg && this.itemImg.split(',').length > 0
          ? this.itemImg.split(',')[1]
          : ''

      if (this.code == null) {
        this.code = ''
      }

      if (this.barcode == null) {
        this.barcode = ''
      }
      const payload = {
        action: {
          operation: 'set',
          entity: 'item',
          params: {
            id: uuidv4(),
            name: this.name.toLowerCase().replace(/\s*$/, ''),
            code: this.code,
            type: this.type.value,
            company_id: state.getCurrentCompany().id,
            storage_unit: this.storageUnit ? this.storageUnit.value : '',
            selling_unit: this.sellingUnit ? this.sellingUnit.value : '',
            warehouses: this.selectedLocations
          }
        }
      }

      if (this.itemImg && this.itemImg !== '') {
        payload.action.params.picture = this.itemImg
      }

      // Ako je skladiste upaljeno salje se na drugi entitet sa drugim podacima
      if (this.warehouseModule) {
        payload.action.entity = 'warehouse_module_item'
        const units = []
        const mpUnits = new Map()

        const newUuid = uuidv4()
        let defaultStore = ''
        let defaultSale = ''

        const baseUnit = {
          id: newUuid,
          base_unit: true,
          storage_unit: this.whStorageUnit,
          storage_amount: 1 * this.multiplier,
          is_selling_item: this.whSellingItem ? this.whSellingItem : false,
          default_sale: this.whDefaultSale ? this.whDefaultSale : false,
          default_store: this.whDefaultStore ? this.whDefaultStore : false
        }

        units.push(baseUnit)
        mpUnits.set(newUuid, baseUnit)

        if (this.whDefaultSale !== undefined && this.whDefaultSale !== false) {
          defaultSale = newUuid
        }

        if (this.whDefaultStore !== undefined && this.whDefaultStore !== false) {
          defaultStore = newUuid
        }

        this.newUnits.forEach((unit) => {
          const newUuid = uuidv4()

          if (unit.defaultSale !== undefined && unit.defaultSale !== false) {
            defaultSale = newUuid
          }

          if (unit.defaultStore !== undefined && unit.defaultStore !== false) {
            defaultStore = newUuid
          }

          const temp = {
            id: newUuid,
            storage_unit: unit.customItemUnit,
            storage_amount: (parseFloat(unit.customItemAmount.replace(',', '.')) * this.multiplier),
            is_selling_item: unit.sellingItem ? unit.sellingItem : false,
            default_sale: unit.defaultSale ? unit.defaultSale : false,
            default_store: unit.defaultStore ? unit.defaultStore : false,
            base_unit: false
          }

          units.push(temp)
          mpUnits.set(newUuid, temp)
        })

        const whUnit = {
          base_unit: newUuid,
          default_sale: defaultSale,
          default_store: defaultStore,
          units: units
        }

        payload.action.params.warehouse_units = whUnit
      }

      if (this.barcode !== '') {
        payload.action.params.barcode = this.barcode
      }
      const resp = payload.action.params

      var companyitemApi = functions.httpsCallable('companyitemapi')

      companyitemApi({
        action: {
          operation: 'set',
          entity: 'item',
          resource_id: '',
          params: payload.action.params
        }
      })
        .then((result) => {
          if (result.data.code === 200) {
            this.$emit('success', { ...resp, ...{ action: 'ADD' } })
            this.$refs.imageUpl.reset()
            this.$refs.addItemForm.reset()
            this.submitting = false
            this.visible = false
          } else {
            this.showMsgBox({
              text: result.data.message ?? 'Dogodila se pogreška.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.submitting = false
          }
        })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }

}
</script>
