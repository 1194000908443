<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="750"
    style="z-index: 6"
  >
    <v-form ref="editAssociateForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">
              {{ $t("$vuetify.company.associate") }}
            </h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center">
            <v-col cols="12">
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    autofocus
                    :label="$t('$vuetify.formDetails.name')"
                    v-model="associate.name"
                    :rules="[rules.req]"
                    class="uppercased-input"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-combobox
                    v-model="associate.oibs"
                    :items="[]"
                    :label="$t('$vuetify.formDetails.oib')"
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    :rules="[rules.arrReq]"
                  >
                  </v-combobox>
                </v-col>
                <v-col cols="4">
                  <v-select
                    :label="$t('$vuetify.formDetails.associateType')"
                    multiple
                    :items="associateTypes"
                    v-model="selectedAssociateTypes"
                    item-text="name"
                    item-value="value"
                    :rules="[rules.arrReq]"
                    small-chips
                    deletable-chips
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :label="$t('$vuetify.formDetails.country')"
                    :items="countries"
                    v-model="associate.country"
                    item-text="country"
                    item-value="country"
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('$vuetify.formDetails.address')"
                    v-model="associate.address"
                    :rules="[rules.req]"
                    class="uppercased-input"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('$vuetify.formDetails.city')"
                    v-model="associate.city"
                    :rules="[rules.req]"
                    class="uppercased-input"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('$vuetify.formDetails.zipCode')"
                    v-model="associate.zip_code"
                    :rules="[rules.req]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('$vuetify.formDetails.phone')"
                    v-model="associate.phone"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('$vuetify.formDetails.mobile')"
                    v-model="associate.mobile"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Email"
                    v-model="associate.email"
                    :rules="[rules.email]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('$vuetify.formDetails.associateCode')"
                    v-model="associate.code"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="IBAN"
                    v-model="associate.ibans"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Web"
                    v-model="associate.web"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-textarea
                    :label="$t('$vuetify.formDetails.additionalInfo')"
                    v-model="associate.additional_info"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            {{ $t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
import countries from '@/mixins/countries'
import { clone } from '@/plugins/utils'
import requestChecker from '@/mixins/requestChecker'

export default {
  data: function (vm) {
    return {
      existingOibs: undefined,
      filteredOibs: [],
      visible: false,
      submitting: false,
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        email: rules.email(),
        arrReq: rules.arrReq()
      },
      associate: {},
      selectedAssociateTypes: [],
      associateTypes: [
        { name: vm.$t('$vuetify.formDetails.associateBuyer'), value: 'BUYER' },
        {
          name: vm.$t('$vuetify.formDetails.associateSupplier'),
          value: 'SUPPLIER'
        }
      ]
    }
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [countries, requestChecker],
  methods: {
    open (associate, associates) {
      this.existingOibs = associates.flatMap((a) => {
        if (a.oibs && a.oibs.length > 0) {
          return a.oibs.flat(x => x)
        }
      })

      this.filteredOibs = this.existingOibs.filter(oib => {
        if (oib && associate.oibs) {
          return !associate.oibs.includes(oib)
        }
      })

      this.visible = true
      this.submitting = false
      this.associate = Object.assign(this.associate, associate)
      this.setInitialAssociateTypes()

      this.registerRequestParams(this.associate)
    },
    close () {
      this.visible = false
    },
    validateOib () {
      let validated = true
      this.associate.oibs.forEach(oib => {
        if (this.filteredOibs.includes(oib)) {
          this.showMsgBox({
            text: this.$t('$vuetify.formDetails.oibDuplicate'),
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          validated = false
        }
      })
      return validated
    },
    removeOib (item) {
      this.associate.oibs = this.associate.oibs.filter((o) => o !== item)
      this.$forceUpdate()
    },

    setInitialAssociateTypes () {
      const associateTypes = this.associate.associate_type
        ? clone(this.associate.associate_type)
        : []
      this.selectedAssociateTypes = []
      Object.keys(associateTypes).forEach((key) => {
        if (associateTypes[key]) this.selectedAssociateTypes.push(key)
      })
    },
    submit () {
      const that = this
      if (!this.$refs.editAssociateForm.validate()) return
      if (!this.validateOib()) return

      this.submitting = true
      const reqId = uuidv4()

      const payload = {
        action: {
          operation: 'update',
          entity: 'associate',
          resource_id: this.associate.id,
          params: {
            company_id: state.getCurrentCompany().id,
            name: this.associate.name.toLowerCase(),
            oibs: this.associate.oibs.map((oib) => oib.trim()),
            address: this.associate.address.toLowerCase(),
            city: this.associate.city.toLowerCase(),
            zip_code: this.associate.zip_code,
            country: this.associate.country,
            code: this.associate.code
          }
        }
      }

      payload.action.params.associate_type = this.selectedAssociateTypes.reduce(
        (associateTypes, associateType) =>
          Object.assign(associateTypes, { [associateType]: true }),
        {}
      )

      if (this.associate.additional_info) {
        payload.action.params = {
          ...payload.action.params,
          ...{ additional_info: this.associate.additional_info }
        }
      }
      if (this.associate.mobile) {
        payload.action.params = {
          ...payload.action.params,
          ...{ mobile: this.associate.mobile }
        }
      }
      if (this.associate.phone) {
        payload.action.params = {
          ...payload.action.params,
          ...{ phone: this.associate.phone }
        }
      }
      if (this.associate.email) {
        payload.action.params = {
          ...payload.action.params,
          ...{ email: this.associate.email }
        }
      }
      if (this.associate.web) {
        payload.action.params = {
          ...payload.action.params,
          ...{ web: this.associate.web }
        }
      }
      if (this.associate.ibans) {
        let iban
        if (Array.isArray(this.associate.ibans)) {
          iban = this.associate.ibans
        } else {
          iban = [this.associate.ibans]
        }
        payload.action.params = { ...payload.action.params, ...{ ibans: iban } }
      }

      const cleanParams = this.getCleanParams(this.associate)
      Object.keys(cleanParams).forEach((key) => {
        if (key !== 'company_id' && key !== 'associate_type') {
          delete payload.action.params[key]
        }
      })

      try {
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'company',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
            that.$emit('successEdit', that.associate)
            that.submitting = false
            that.visible = false
          })
          .catch(function (err) {
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.submitting = false
          })
      } catch (err) {
        that.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        that.submitting = false
      }
    }
  }
}
</script>
<style scoped>
.uppercased-input >>> input {
  text-transform: uppercase !important;
}
</style>
