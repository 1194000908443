<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="750"
    style="z-index: 6"
  >
    <v-form ref="addSupplierForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 ">
            <h3 class="text--secondary">
              {{ $t("$vuetify.company.addSupplier") }}
            </h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center">
            <v-col cols="12">
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    outlined
                    dense
                    autofocus
                    :label="$t('$vuetify.formDetails.name')"
                    v-model="name"
                    :rules="[rules.req]"
                    class="uppercased-input mt-3"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    :label="$t('$vuetify.formDetails.oib')"
                    v-model="oib"
                    :rules="[rules.req]"
                    class="uppercased-input mt-3"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-col cols="8" class="pb-0">
                <v-select
                  multiple
                  outlined
                  dense
                  clearable
                  label="Odaberi prodajna mjesta"
                  :items="locations"
                  id="locations"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  v-model="selectedLocations"
                  :rules="[rules.arrReq]"
                  ref="warehouses"
                ></v-select>
              </v-col>
                <v-col class="pt-0" cols="4">
                  <v-select
                    outlined
                    dense
                    label="Tip komunikacije"
                    :items="integrationTypes"
                    v-model="selectedIntegrationType"
                    item-text="name"
                    item-value="value"
                    class="uppercased-input mt-3"

                    :rules="[rules.arrReq]"
                    small-chips
                    deletable-chips
                  ></v-select>
                </v-col>
              </v-row>
              <div v-if="selectedIntegrationType === 'roto'" class="pb-3">
                <h3>Korisnički podaci potrebni za integraciju</h3>
              </div>
              <v-row v-if="selectedIntegrationType === 'roto'">
                <v-col>
                  <v-text-field
                    :label="$t('$vuetify.formDetails.username')"
                    v-model="username"
                    :rules="[rules.req]"
                    class="uppercased-input"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :label="$t('$vuetify.formDetails.password')"
                    v-model="password"
                    :rules="[rules.req]"
                    class="uppercased-input"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-col class="pt-0">
                  <v-select
                    :label="$t('$vuetify.formDetails.country')"
                    :items="countries"
                    v-model="country"
                    item-text="country"
                    item-value="country"
                    :rules="[rules.req]"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field
                    :label="$t('$vuetify.formDetails.address')"
                    v-model="address"
                    :rules="[rules.req]"
                    class="uppercased-input"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field
                    :label="$t('$vuetify.formDetails.city')"
                    v-model="city"
                    :rules="[rules.req]"
                    class="uppercased-input"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field
                    :label="$t('$vuetify.formDetails.zipCode')"
                    v-model="zipCode"
                    :rules="[rules.req]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-text-field
                    :label="$t('$vuetify.formDetails.phone')"
                    v-model="phone"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field
                    :label="$t('$vuetify.formDetails.mobile')"
                    v-model="mobile"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field
                    label="Email"
                    v-model="email"
                    :rules="[rules.email]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('$vuetify.formDetails.supplierCode')"
                    v-model="code"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="IBAN"
                    v-model="ibans"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Web"
                    v-model="web"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-textarea
                    :label="$t('$vuetify.formDetails.additionalInfo')"
                    v-model="additional_info"
                    outlined
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            {{ $t("$vuetify.company.addSupplier") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
import countries from '@/mixins/countries'

const optional = [
  'phone',
  'mobile',
  'email',
  'ibans',
  'web',
  'additional_info',
  'code'
]
export default {
  data: function (vm) {
    return {
      visible: false,
      submitting: false,
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        email: rules.email(),
        arrReq: rules.arrReq()
      },
      name: undefined,
      oib: undefined,
      address: undefined,
      city: undefined,
      zipCode: undefined,
      country: undefined,
      phone: undefined,
      mobile: undefined,
      email: undefined,
      ibans: undefined,
      web: undefined,
      additional_info: undefined,
      code: undefined,
      oibs: [],
      selectedIntegrationType: [],
      integrationTypes: [
        { name: 'Email', value: 'email' }
        // { name: 'Roto dinamic integracija', value: 'roto' }
      ],
      username: undefined,
      password: undefined,
      selectedLocations: [],
      locations: [],
      listeners: []
    }
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [countries],
  mounted () {
    this.listeners.push(
      df
        .doc(
            `user_locations/${auth.currentUser.uid}.${
              state.getCurrentCompany().id
            }`
        )
        .onSnapshot((doc) => {
          if (
            doc.data() &&
              doc.data().locations &&
              Object.keys(doc.data().locations).length > 0
          ) {
            this.locations = Object.keys(doc.data().locations)
              .map((key) => {
                return doc.data().locations[key]
              })
              .filter((location) => location.status === 'OK')

            this.selectedLocations = this.locations.map((location) => {
              return location.id
            })
          }
        })
    )
  },
  methods: {
    open () {
      this.visible = true
      this.submitting = false
    },
    close () {
      this.visible = false
    },
    removeOib (item) {
      this.oibs = this.oibs.filter((o) => o !== item)
    },
    submit () {
      const that = this
      if (!this.$refs.addSupplierForm.validate()) return

      //   this.showLoader()
      this.submitting = true
      const reqId = uuidv4()
      const payload = {
        action: {
          operation: 'set',
          entity: 'supplier',
          params: {
            company_id: state.getCurrentCompany().id,
            locations: this.selectedLocations,
            name: this.name.toLowerCase(),
            oib: this.oib,
            // oibs: this.oibs.map((oib) => oib.trim()),
            address: this.address.toLowerCase(),
            city: this.city.toLowerCase(),
            zip_code: this.zipCode,
            country: this.country,
            integration_type: this.selectedIntegrationType
          }
        }
      }

      if (this.selectedIntegrationType === 'roto') {
        payload.action.params.username = this.username
        payload.action.params.password = this.password
      }
      optional.forEach((option) => {
        if (this[option] && this[option] !== '') {
          payload.action.params = {
            ...payload.action.params,
            [option]: option === 'ibans' ? [this[option]] : this[option]
          }
        }
      })

      that.$emit('success', payload.action.params)
      try {
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'supplier',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
            // setTimeout(() => {
            that.$refs.addSupplierForm.reset()
            that.submitting = false
            that.visible = false
            // }, 100);
          })
          .catch(function (err) {
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.submitting = false
          })
      } catch (err) {
        that.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        that.submitting = false
      }
    }
  }
}
</script>
<style scoped>
.uppercased-input >>> input {
  text-transform: uppercase !important;
}
</style>
