var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",staticStyle:{"width":"100%"}},[_c('v-row',{staticClass:"pt-3 pr-3"},[_c('v-spacer'),_c('Search-box',{attrs:{"emitDestination":_vm.searchEmitDestination,"searchPlaceholder":"Pretraži artikle"}})],1),_c('v-container',[(_vm.listView)?_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"0px"}},[_c('v-col',{class:['px-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},_vm._l((_vm.items),function(item,i){return _c('v-card',{key:i,attrs:{"hover":""}},[_c('v-row',{class:[
              'ma-0',
              item.status === 'OK'
                ? 'status-ok-exp'
                : ("" + (item.status === 'PROCESSING'
                      ? 'status-processing-exp'
                      : 'status-failed-exp')) ],staticStyle:{"height":"48px !important"}},[(_vm.density !== 'greater')?_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"6% !important"}},[(item.picture)?_c('v-img',{attrs:{"src":item.picture,"contain":"","height":_vm.density === 'normal' ? '44' : '68'}}):_c('v-img',{attrs:{"src":require("@/assets/no-item.jpg"),"height":_vm.density === 'normal' ? '44' : '68'}})],1):_vm._e(),_c('v-col',{class:[_vm.densityPadding, 'flex-grow-1'],staticStyle:{"min-width":"100px","max-width":"100%","padding-bottom":"10px !important"},attrs:{"cols":"5","sm":"5","md":"3","lg":"3"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Naziv")]),_c('div',{ref:("hover-" + (item.id)),refInFor:true,staticClass:"one-liner-text",attrs:{"id":("hover-" + (item.id))},on:{"mouseover":function($event){return _vm.hoverOver(("hover-" + (item.id)), item)},"mouseleave":function($event){item[("hover-" + (item.id))] = false}}},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(item.name))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on))]}}],null,true),model:{value:(item[("hover-" + (item.id))]),callback:function ($$v) {_vm.$set(item, ("hover-" + (item.id)), $$v)},expression:"item[`hover-${item.id}`]"}},[_c('div',{staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(item.name))+" ")])])],1),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"3","sm":"3","md":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Šifra")]),_c('div',[_vm._v(_vm._s(item.code))])]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"4","sm":"4","md":"3","lg":"3"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Vrsta")]),_c('div',[_vm._v(" "+_vm._s(_vm.showItemType(item.type))+" ")])]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"6","sm":"4","md":"2","lg":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Status")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({class:[
                      item.status === 'OK'
                        ? 'success--text'
                        : ("" + (item.status === 'PROCESSING'
                              ? 'info--text'
                              : 'error--text')) ]},on),[_vm._v(" "+_vm._s(_vm.translateStatus(item.status))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getStatusTooltip(item.status))+" ")])],1),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"2","md":"1","lg":"1"}},[_c('div',{staticClass:"d-flex align-start justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0 pt-0",attrs:{"x-small":_vm.density === 'greater',"icon":"","disabled":item.status !== 'OK'},on:{"click":function($event){return _vm.addItemPrice(item)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItemPrice(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-credit-card-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Dodaj artikl na cjenik")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0 pt-0",attrs:{"x-small":_vm.density === 'greater',"icon":""},on:{"click":function($event){return _vm.openEditForm(item)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openEditForm(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi artikl")])])],1)])],1),_c('v-divider')],1)}),1)],1):_c('v-row',{staticClass:"d-flex justify-center align-center fill-height ma-0 pa-0"},_vm._l((_vm.items),function(item,i){return _c('v-card',{key:i,staticClass:"align-center ma-5",attrs:{"hover":"","height":"300","width":"250"}},[(item.picture)?_c('v-img',{attrs:{"height":"190","width":"250","src":item.picture}}):_c('v-img',{attrs:{"height":"190","width":"250","src":require("@/assets/no-item.jpg")}}),_c('div',{staticClass:"mr-4 ml-4"},[_c('div',[_c('h3',{staticClass:"selling-point-title-text one-liner-text"},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(item.name))+" ")]),_c('p',{staticClass:"grey--text mb-1 selling-point-text one-liner-text"},[_vm._v(" Status: "+_vm._s(item.status)+" ")])])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(!_vm.warehouseModule)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(!_vm.warehouseModule)?{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(!_vm.warehouseModule)?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0 pt-0",attrs:{"x-small":_vm.density === 'greater',"icon":"","disabled":item.status !== 'OK'},on:{"click":function($event){return _vm.addItemPrice(item)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItemPrice(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-credit-card-plus")])],1):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("Dodaj artikl na cjenik")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.status === 'PROCESSING',"icon":""},on:{"click":function($event){return _vm.openEditForm(item)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openEditForm(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi artikl")])])],1)],1)}),1),_c('infinite-loading',{ref:"InfiniteLoading",attrs:{"spinner":"waveDots"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-results"},slot:"no-results"})])],1),(!_vm.warehouseModule)?_c('add-item',{ref:"addItem",on:{"refresh":_vm.refresh}}):_vm._e(),(_vm.warehouseModule)?_c('add-wh-item',{ref:"addWhItem",on:{"refresh":_vm.refresh}}):_vm._e(),(!_vm.warehouseModule)?_c('edit-item',{ref:"editItem",on:{"refresh":_vm.refresh}}):_vm._e(),(_vm.warehouseModule)?_c('edit-wh-item',{ref:"editWhItem",on:{"refresh":_vm.refresh}}):_vm._e(),_c('add-pricelist-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.warehouseModule),expression:"!warehouseModule"}],ref:"addPricelistItem"}),_c('add-wh-pricelist-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.warehouseModule),expression:"warehouseModule"}],ref:"addWhPricelistItem"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }