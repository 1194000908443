<template>
  <div style="width: 100%" class="fill-height">
    <v-btn
      style="z-index: 4"
      rounded
      fixed
      bottom
      right
      color="primary"
      @click="openaddItemForm"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-icon>mdi-plus</v-icon>
      <span>{{ $t(`$vuetify.company.addLagerItemBtn`) }}</span>
    </v-btn>
    <v-container>
      <v-row
        v-if="listView"
        style="margin-top: 40px"
        class="d-flex justify-center"
      >
        <v-col cols="12" sm="11" lg="10" :class="['px-0']">
          <v-card v-for="(item, i) in items" :key="i" hover>
            <v-row
              :class="[
                'ma-0',
                item.status === 'OK'
                  ? 'status-ok'
                  : `${
                      item.status === 'PROCESSING'
                        ? 'status-processing'
                        : 'status-failed'
                    }`,
              ]"
            >
              <v-col
                v-if="density !== 'greater'"
                cols="4"
                sm="4"
                md="1"
                :class="[densityPadding]"
              >
                <v-img
                  v-if="item.picture"
                  :src="item.picture"
                  contain
                  :height="density === 'normal' ? '48' : '68'"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/no-item.jpg"
                  :height="density === 'normal' ? '48' : '68'"
                ></v-img>
              </v-col>
              <v-col
                cols="5"
                sm="5"
                md="3"
                lg="3"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1']"
              >
                <div class="caption grey--text">Naziv artikla</div>
                <div
                  class="text-subtitle-1 one-liner-text"
                  @mouseover="hoverOver(`hover-${item.id}`, item)"
                  @mouseleave="item[`hover-${item.id}`] = false"
                  :id="`hover-${item.id}`"
                  :ref="`hover-${item.id}`"
                >
                  {{ $options.filters.capitalize(item.name) }}
                </div>
                <v-tooltip v-model="item[`hover-${item.id}`]" bottom>
                  <!--Fake activator to avoid an attach property which is not working properly -->
                  <template v-slot:activator="{ on }">
                    <div v-on="on"></div>
                  </template>
                  <div style="max-width: 300px">
                    {{ $options.filters.capitalize(item.name) }}
                  </div>
                </v-tooltip>
              </v-col>
              <v-col cols="3" sm="3" md="2" :class="[densityPadding]">
                <div class="caption grey--text">Sifra artikla</div>
                <div>{{ item.code }}</div>
              </v-col>
              <v-col cols="4" sm="4" md="3" lg="3" :class="[densityPadding]">
                <div class="caption grey--text">Vrsta artikla</div>
                <div>
                  {{
                    $t(`$vuetify.lager.itemTypes.${item.type.toLowerCase()}`)
                  }}
                </div>
              </v-col>
              <v-col cols="6" sm="4" md="2" lg="2" :class="[densityPadding]">
                <div class="caption grey--text">Status artikla</div>

                <div
                  :class="[
                    item.status === 'OK'
                      ? 'success--text'
                      : `${
                          item.status === 'PROCESSING'
                            ? 'info--text'
                            : 'error--text'
                        }`,
                  ]"
                >
                  {{ item.status }}
                </div>
              </v-col>
              <!-- <v-col cols="2" md="1" lg="1" :class="[densityPadding]">
                <div class="caption grey--text text-center">Akcije</div>
                <div class="d-flex align-start justify-center">
                  <v-tooltip v-if="!warehouseModule" bottom>
                    <template
                      v-if="!warehouseModule"
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-btn
                        v-if="!warehouseModule"
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        :disabled="item.status !== 'OK'"
                        @click="addItemPrice(item)"
                        @keypress.enter="addItemPrice(item)"
                      >
                        <v-icon>mdi-credit-card-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Dodaj artikl na cjenik</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        :disabled="item.status === 'PROCESSING'"
                        @click="openEditForm(item)"
                        @keypress.enter="openEditForm(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi artikl</span>
                  </v-tooltip>
                </div>
              </v-col> -->
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="d-flex justify-center align-center fill-height ma-0 pa-0"
      >
        <v-card
          class="align-center ma-5"
          v-for="(item, i) in items"
          :key="i"
          hover
          height="300"
          width="250"
        >
          <v-img
            height="190"
            width="250"
            v-if="item.picture"
            :src="item.picture"
          >
          </v-img>
          <v-img height="190" width="250" v-else src="@/assets/no-item.jpg">
          </v-img>
          <div class="mr-4 ml-4">
            <div>
              <h3 class="selling-point-title-text one-liner-text">
                {{ $options.filters.capitalize(item.name) }}
              </h3>
              <p class="grey--text mb-1 selling-point-text one-liner-text">
                Status: {{ item.status }}
              </p>
            </div>
          </div>
          <!-- <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :disabled="item.status === 'PROCESSING'"
                  icon
                  @click="openEditForm(item)"
                  @keypress.enter="openEditForm(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Uredi artikl</span>
            </v-tooltip>
          </v-card-actions> -->
        </v-card>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more" class="text--secondary font-italic"></div>
        <div slot="no-results" class="text--secondary font-italic"></div>
      </infinite-loading>
    </v-container>
    <!-- <add-wh-item
      v-if="warehouseModule"
      ref="addWhItem"
      @success="refreshLager"
    ></add-wh-item>
    <edit-wh-item
      v-if="warehouseModule"
      ref="editWhItem"
      @success="refreshLager"
    ></edit-wh-item> -->
    <add-pricelist-item v-if="!warehouseModule" ref="addPricelistItem" />
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
// import addWhItem from '@/modules/company/components/norm/AddWhNormItem'
// import editWhItem from '@/modules/company/components/norm/EditWhNormItem'
import InfiniteLoading from 'vue-infinite-loading'
import addPricelistItem from '@/modules/point-of-sale/components/AddPricelistItem'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import { clone } from '@/plugins/utils'
export default {
  inject: ['showLoader', 'confirm', 'confirmClose', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  components: { InfiniteLoading, addPricelistItem },
  data: () => ({
    loading: false,
    lastiItem: false,
    options: {},
    pagination: {
      itemsPerPage: 10, // TODO: change to 30 after testing.
      page: 1
    },
    headers: [],
    items: [],
    avatarSize: 120,
    lastVisible: '',
    listeners: [],
    newItem: false,
    warehouseModule: false
  }),
  mounted () {
    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }
  },
  computed: {
    listView () {
      return state.isListView()
    }
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    companyId (nv, ov) {
      if (nv !== ov) {
        this.detachListeners()
        this.items = []
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    search () {
      this.lastVisible = ''
      this.items = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    }
  },
  methods: {
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    openaddItemForm () {
      if (this.warehouseModule) {
        this.$refs.addWhItem.open()
      } else {
        this.$refs.addItem.open()
      }
    },
    openEditForm (item) {
      if (this.warehouseModule) {
        this.$refs.editWhItem.open(item)
      } else {
        this.$refs.editItem.open(item)
      }
    },
    refreshLager (item) {
      if (item.action === 'ADD') {
        this.newItem = true
        this.items.unshift(
          { ...item, ...{ status: 'PROCESSING' } }
        )
        this.$vuetify.goTo(0)
      } else if (item.action === 'EDIT') {
        this.items = this.items.map(_item => {
          if (_item.id === item.id) {
            _item.name = item.name
            _item.status = 'PROCESSING'
          }
          return _item
        })
      }
    },
    addItemPrice (item) {
      this.$refs.addPricelistItem.open(item)
    },
    modifyItems (change) {
      if (change.type === 'added') {
        if (this.items.some(item =>
          (item.id === change.doc.data().id) ||
          (item.name.toLowerCase() === change.doc.data().name && item.status === 'PROCESSING')
        )) {
          this.items = this.items.map(item => {
            let ret = item
            if (((item.id === change.doc.data().id) || (item.name === change.doc.data().name.toLowerCase() && item.status === 'PROCESSING')) && this.newItem) {
              ret = change.doc.data()
              if (ret.status === 'OK' && !this.warehouseModule) {
                this.confirm({
                  title: 'Dodaj cijenu',
                  message: `Dodaj cijenu za novododani artikl ${item.name} ?`,
                  options: {
                    toolbar: true,
                    confirmText: 'Dodaj',
                    cancelText: 'Odustani'
                  }
                }).then(resp => {
                  if (resp) {
                    this.addItemPrice(change.doc.data())
                  }
                  this.confirmClose()
                }).finally(() => {
                  this.newItem = false
                })
              }
            }
            return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
          })
        } else {
          this.items.push({ ...change.doc.data(), ...{ [`hover-${change.doc.data().id}`]: false } })
        }
      }
      if (change.type === 'modified') {
        this.items = this.items.map(item => {
          let ret = item
          if (item.id === change.doc.data().id) {
            ret = change.doc.data()
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }

      this.items = clone(this.items.sort((a, b) => a.name.localeCompare(b.name)))
    },
    infiniteHandler ($state) {
      let search = 'name'
      if (this.isBarcodeSearch(this.search)) {
        search = 'barcode'
      }
      const where = this.search ? this.search.toLowerCase() : ''

      let query = df.collection(`companies/${state.getCurrentCompany().id}/items`)
        .orderBy(search, 'asc')
        .limit(10)
        .startAfter(this.lastVisible)
      if (where !== undefined && where !== '') {
        query = query.where(search, '>=', where).where(search, '<=', where + '\uf8ff')
      }
      query = query.where('has_norm', '==', true)
      const listener = query
        .onSnapshot((doc) => {
          doc.docChanges().forEach(change => {
            this.modifyItems(change)
          })
          if (doc && !doc.empty) {
            if (doc.docs[doc.docs.length - 1]) {
              this.lastVisible = doc.docs[doc.docs.length - 1]
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        }
        // (error) => {
        //   console.log('error', error)
        // }
        )

      this.listeners.push(listener)
    }
  }
}
</script>
